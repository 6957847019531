<template lang="pug">
div#geofence
  card(title='Geofences')
    .d-flex.mb-4.py-3
      div.w-100.pr-4
        input.w-100.h-100.form-control.search-input.border-0(:style="search ? 'background: #fafafc' : 'background: #fafafc url(/icons/search-icon.png) no-repeat right 10px center;background-size: 26px;'" type='search' v-model="search" placeholder='Search' autocomplete='off')
      .p-0.d-flex
        matador-button.py-2.px-4.ml-2(icon="fa fa-plus" @click="onAddGeofence") Add Geofence
    //- .d-flex.justify-content-end
      div(style="width: 220px")
        .input-group
          input.form-control.search-input(type='search' v-model="search" placeholder='Search' autocomplete='off')
          .input-group-append
            button.btn.btn-secondary(@click="getGeofences" type='button' name='search' title='Search')
              i.fa.fa-search
      div.ml-2
        button.btn.btn-danger(@click="onAddGeofence")
          img.mr-1(:width="18" style="margin-top: -3px" src="@/assets/img/plus-white.png")
          span Add Geofence
    loader(:loading="loading")
      p.text-center(v-if="geofences.length == 0") No data available
      .row
        .col-md-4(v-for="item in shownGeofences")
          .card
            .card-body(@mouseover="geofence = item")
              geofence-card(:geofence="item")
              .d-flex.mb-3.justify-content-between
                h5.mb-0 {{ item.name }}
                .pl-2
                  dropdown.dropdown-toggle-no-caret(tag='a' icon='mdi mdi-dots-vertical h4 text-dark m-0')
                    li.p-3.matador-text-03(@click="showModal = true") Edit Properties
                    li.p-3.matador-text-03(@click="showDeleteConfirmation = true") Delete Geofence
              p.heading Description
              p {{ item.description }}
              p.heading Devices
              p {{ item.device.map(item => item.device_name).join(", ") }}
              matador-button(:block="true" size="lg" :outline="true" style="border-radius: 15px" @click="showModal = true") View Detail
      //- table.w-100(v-if="!loading")
        tbody(v-for="(item, index) in shownGeofences" :key="item.device_id")
          tr.device-list
            td(:colspan="4" :class="{'border-bottom': !item.expanded}")
              .device-list
                a.text-dark.align-items-center.d-flex.justify-content-between.py-2(href="javascript:;" @click="toggleRow(index)")
                  p.m-0 {{ item.name }}
                  .pr-2.mt-3
                    chevron-up.m-0(v-if="item.expanded" :size="40")
                    chevron-down.m-0(v-if="!item.expanded" :size="40")
          tr.device-list(v-show="item.expanded" @mouseover="geofence = item")
            td.pt-3.border-bottom.pb-3(valign="top")
              p.font-weight-bold.mt-0.mb-2 Description
              p.m-0.pr-lg-4 {{ item.description }}
            td.pt-3.border-bottom.pb-3(:width="260" valign="top")
              p.font-weight-bold.mt-0.mb-2 Device
              p.m-0(style="white-space: pre-line") {{ item.device.map(item => item.device_name).join("\n") }}
            td.pt-3.border-bottom.pb-3.text-right(:width="138" valign="top")
              button.px-4.btn.btn-sm.d-inline-flex.align-items-center.btn-outline-danger(@click="showModal = true")
                | View Geofence
            td.pt-3.border-bottom.pb-3.text-right(:width="70" valign="top")
              button.text-danger.p-1.border-0.bg-transparent(@click="showDeleteConfirmation = true")
                delete-icon
      //- .pt-4.mt-3
        .d-flex.align-item-center.justify-content-between
          b-pagination(
            v-model="page"
            :total-rows="geofences.length"
            :per-page="perPage"
            aria-controls="my-table"
          )
          div.d-flex.align-items-center
            span.mr-2 Per Page : 
            b-dropdown(:text='perPage.toString()' variant='light')
              b-dropdown-item(@click="perPage = 10") 10
              b-dropdown-item(@click="perPage = 25") 25
              b-dropdown-item(@click="perPage = 50") 50
              b-dropdown-item(@click="perPage = 100") 100
  div(v-if="showModal")
    geofence-form-modal(@close="onCloseModal" :geofence="geofence")
  confirmation-modal(
    v-if="!!showDeleteConfirmation"
    @close="showDeleteConfirmation = null" 
    @confirm="onDeleteGeofence"
    title="Delete Geofence" 
    confirm-text="Yes, Delete"
  )
    p.m-0
      | Do you want to delet this alert?
</template>
<script>
import ChevronDown from "vue-material-design-icons/ChevronDown.vue";
import ChevronUp from "vue-material-design-icons/ChevronUp.vue";
import DeleteIcon from "vue-material-design-icons/DeleteOutline.vue";
import GeofenceFormModal from "../../../components/Matador/Geofence/GeofenceFormModal.vue";
import ConfirmationModal from "../../../components/Matador/ConfirmationModal.vue";
import GeofenceCard from "./GeofenceCard.vue";
import { getGeofence, postDeleteGeofence } from "../../../utils/api";
import Dropdown from "@/components/Dropdown.vue";
export default {
  components: {
    ChevronDown,
    ChevronUp,
    GeofenceFormModal,
    DeleteIcon,
    ConfirmationModal,
    GeofenceCard,
    Dropdown
  },
  watch: {
    search: {
      handler: function(newVal) {
        // Menggunakan debounce untuk menunda pencarian
        typeof this.timeout != "undefined" && clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.getGeofences({ search: newVal });
        }, 500);
      },
      deep: true // Menyertakan deep: true agar dapat mendeteksi perubahan dalam objek pencarian
    },
    perPage: {
      handler: function(newVal, oldVal) {
        if (newVal != oldVal) {
          this.page = 1;
        }
      }
    }
  },
  computed: {
    shownGeofences() {
      const geofences = JSON.parse(JSON.stringify(this.geofences));
      const result = geofences;
      return result;
    }
  },
  data() {
    return {
      page: 1,
      showModal: false,
      loading: false,
      perPage: 10,
      geofence: null,
      search: "",
      geofences: [],
      showDeleteConfirmation: false
    };
  },
  methods: {
    getGeofences(params = {}) {
      params = {
        ...params,
        limit: this.limit || 10,
        page: (this.offset / this.limit || 0) + 1,
        search: encodeURIComponent(this.search)
      };

      getGeofence(params)
        .then(({ data }) => {
          this.geofences = data.data;

          if (!this.geofences || !this.geofences.length) this.geofences = [];
          // this.$store.state.devMode && console.log(result[0]);
        })
        .catch(error => {
          const err = error.response ? error.response.data : error;
          this.$toast.error(err.message || "Failed to load geofences");
        });
    },
    onAddGeofence() {
      this.geofence = null;
      this.showModal = true;
    },
    toggleRow(index) {
      this.loading = true;
      let geofences = JSON.parse(JSON.stringify(this.geofences));
      geofences[
        this.page * this.perPage - this.perPage + index
      ].expanded = !geofences[this.page * this.perPage - this.perPage + index]
        .expanded;
      this.geofences = geofences;
      this.loading = false;
    },
    onCloseModal() {
      this.getGeofences();
      this.showModal = false;
    },
    onDeleteGeofence() {
      postDeleteGeofence({ geofence_id: this.geofence.geofence_id }).then(
        () => {
          this.$toast.open("Successfully deleted geofence");
          this.getGeofences();
          this.showDeleteConfirmation = false;
        }
      );
    }
  },
  mounted() {
    this.getGeofences();
    // if(this.$store.state.devMode){
    //   this.showModal = true;
    // }
  }
};
</script>
<style lang="scss">
@import "../../../assets/sass/paper/variables.scss";
#geofence {
  .material-design-icon {
    > .material-design-icon__svg {
      width: 1.4rem;
      height: 1.4rem;
    }
  }
  .col-md-4 {
    margin-bottom: 1.5rem;
  }
  .card {
    height: 100%;
    .dropdown-menu {
      left: auto;
      right: 0;
    }
    p {
      margin-top: 0;
      margin-bottom: 1.25rem;
      font-size: 0.9rem;
      &.heading {
        margin-bottom: 2px;
        font-size: 0.8rem;
        font-weight: bold;
        color: #999;
      }
    }
  }
}
</style>
