<template lang="pug">
transition(name="modal")
  div
    .modal-mask(v-if="visible")
      .modal-wrapper
        .modal-dialog(role="document")
          .modal-content
            .modal-header
              .h5.font-weight-bold.card-title.px-3 {{ title }} Tracks
              button.close(
                type="button",
                data-dismiss="modal",
                aria-label="Close",
                @click="onClose()"
              )
                span.pr-3(aria-hidden="true", @click="showAdd = false") &times;
            .modal-body
                form.px-3.mt-3
                  .form-group
                    label Nama Kendaraan
                    select.custom-select.form-control(type='text',v-model="device_id"
                     :class="{ 'matador-placeholder': device_id === ''}" style="color:black !important")
                      option(value="" selected disabled) Pilih Nama Kendaraan Perangkat
                      option(v-for="item in deviceList" :value="item.device_id") {{item.description}}
                  .form-group
                    label Pilih Rentang
                    select.custom-select.form-control(type='text',v-model="selectDay" @change="onSelectDayChange($event)"
                     :class="{ 'matador-placeholder': selectDay === ''}" style="color:black !important")
                      option(value="" selected disabled) Pilih Rentang
                      option(value="3") 3 hari terakhir
                      option(value="7") 7 hari terakhir
                      option(value="14") 14 hari terakhir
                      option(value="custom") Tanggal Kustom
                  .row(v-if="selectDay==='custom'")
                    .col-6
                      .form-group
                        label Start Date
                        datepicker(placeholder="Choose Start Date" input-class="form-control" v-model="startDate" :disabled-dates="{ from: new Date() }")
                    .col-6
                      .form-group
                        label Start Time
                        .d-flex.justify-content-between.align-items-start
                          timepicker.flex-grow-1(input-class="form-control" close-on-complete v-model="startHour" format="HH" input-width="90px")
                          span.time-separator :
                          timepicker.flex-grow-1(input-class="form-control" close-on-complete v-model="startMinute" format="mm" input-width="90px")
                  .row(v-if="selectDay==='custom'")
                    .col-6
                      .form-group
                        label End Date
                        datepicker(placeholder="Choose Start Date" input-class="form-control" v-model="endDate" :disabled-dates="{ from: new Date() }")
                    .col-6
                      .form-group
                        label End Time
                        .d-flex.justify-content-between.align-items-start
                          timepicker.flex-grow-1(input-class="form-control" close-on-complete v-model="endHour" format="HH" input-width="90px")
                          span.time-separator :
                          timepicker.flex-grow-1(input-class="form-control" close-on-complete v-model="endMinute" format="mm" input-width="90px")
                    .col-12.mt-n2
                      small.matador-text-03 Choose between 1 to 14 days duration
                  .row.mt-3
                    .col-12
                      .form-group
                        label Tracks
                        //- .row
                        //-   .col-6
                        //-     .form-check
                        //-       input.form-check-input(type="radio" v-model="type" name="tracks" value="raw batch")
                        //-       label.form-check-label Raw Data per batch
                        //-   .col-6
                        //-     .form-check
                        //-       input.form-check-input(type="radio" v-model="type" name="tracks" value="road batch")
                        //-       label.form-check-label Road Filtered per batch
                        .row
                          .col-6
                            .form-check
                              input.form-check-input(type="radio" v-model="type" name="tracks" value="raw once")
                              label.form-check-label Data Mentah Sekaligus
                          .col-6
                            .form-check
                              input.form-check-input(type="radio" v-model="type" name="tracks" value="road once")
                              label.form-check-label Jalan Difilter Sekaligus
                div.float-right.mt-3
                  button.btn.btn-link.mr-3(type="button" @click="onClose()") Batalkan
                  matador-button.float-right(@click="doSave" :disabled="!isValid")  {{ buttonTitle }}
      confirmation-modal(
        v-if="showCancelModal"
        @close="showCancelModal = false" 
        @confirm="doClose()" 
        title="Buang Perubahan"
        confirm-text="Ya, Buang"
      )
        p.m-0
          | Apakah Anda yakin ingin membuang perubahan yang Anda buat?
    confirmation-modal(
      v-if="showDeleteModal"
      @close="showDeleteModal = false" 
      @confirm="onConfirmDelete" 
      title="Delete Data"
      confirm-text="Yes, Delete"
    )
      p.m-0
        | Do yo want to delete this data?
</template>

<script>
import * as yup from "yup";
import Plus from "vue-material-design-icons/Plus.vue";
import EyeOutline from "vue-material-design-icons/EyeOutline.vue";
import EyeOffOutline from "vue-material-design-icons/EyeOffOutline.vue";
import ConfirmationModal from "@/components/Matador/ConfirmationModal";
import Datepicker from "vuejs-datepicker";
import dayjs from "dayjs";
import {
  getDeviceUnit,
  postAddTracking,
  postEditTracking,
  postRemoveTracking,
} from "@/utils/api";
import { doubleDigitFormat } from "@/utils/datetime";

let trackSchema = yup.object().shape({
  user_id: yup.string().required(),
  device_id: yup.string().required(),
  start_date: yup.string().required(),
  end_date: yup
    .string()
    .required()
    .test("start_date", "Day range must between 1-14", function (value) {
      var start = dayjs(this.parent.start_date, "YYYY-MM-DD HH:mm:ss");
      var end = dayjs(value, "YYYY-MM-DD HH:mm:ss");
      var diff = end.diff(start, "day", true);
      return diff >= 1 && diff <= 14;
    }),
  type: yup.string().required(),
});

export default {
  data() {
    return {
      isValid: false,
      tracking_unit_id: "",
      user_id: "",
      device_id: "",
      start_date: "",
      end_date: "",
      type: "",
      selectDay: "",
      startDate: null,
      startHour: "",
      startMinute: "",
      endDate: null,
      endHour: "",
      endMinute: "",
      userList: [],
      deviceList: [],
      showDeleteModal: false,
      showCancelModal: false,
      loading: false,
    };
  },
  watch: {
    currentTrack: function (newVal) {
      if (newVal) {
        this.tracking_unit_id = newVal.tracking_unit_id;
      }
      this.user_id = newVal.user_id || localStorage.user_id;
      this.device_id = newVal.device_id || "";
      this.start_date = newVal.start_date || "";
      this.end_date = newVal.end_date || "";
      this.type = newVal.type || "";

      if (newVal.start_date && newVal.end_date) {
        this.selectDay = "custom";
        var start = dayjs(newVal.start_date);
        var end = dayjs(newVal.end_date);
        this.startDate = start.toDate();
        this.startHour = doubleDigitFormat(start.hour().toString());
        this.startMinute = doubleDigitFormat(start.minute().toString());
        this.endDate = end.toDate();
        this.endHour = doubleDigitFormat(end.hour().toString());
        this.endMinute = doubleDigitFormat(end.minute().toString());
      } else {
        this.selectDay = "";
        this.startDate = null;
        this.startHour = "";
        this.startMinute = "";
        this.endDate = null;
        this.endHour = "";
        this.endMinute = "";
      }
    },
    inputData: function (newVal) {
      this.isValid = trackSchema.isValidSync(newVal);
    },
  },
  components: {
    Plus,
    EyeOutline,
    EyeOffOutline,
    ConfirmationModal,
    Datepicker,
  },
  props: ["close", "currentTrack", "onSave", "onDelete", "visible"],
  computed: {
    isDirty() {
      if (this.currentDevice && this.currentDevice.tracking_unit_id) {
        return (
          this.currentDevice.device_id != this.device_id ||
          this.currentDevice.start_date != this.start_date ||
          this.currentDevice.end_date != this.end_date ||
          this.currentDevice.type != this.type
        );
      }
      return this.device_id || this.start_date || this.end_date || this.type;
    },
    inputData() {
      let obj = {
        tracking_unit_id: this.tracking_unit_id,
        user_id: this.user_id,
        device_id: this.device_id,
        start_date:
          this.startDate && this.startHour && this.startMinute
            ? dayjs(this.startDate)
                .hour(this.startHour)
                .minute(this.startMinute)
                .format("YYYY-MM-DD HH:mm:ss")
            : null,
        end_date:
          this.endDate && this.endHour && this.endMinute
            ? dayjs(this.endDate)
                .hour(this.endHour)
                .minute(this.endMinute)
                .format("YYYY-MM-DD HH:mm:ss")
            : null,
        type: this.type,
      };
      return obj;
    },
    title() {
      return !!this.tracking_unit_id ? "Memperbarui" : "Tambahkan";
    },
    buttonTitle() {
      return !!this.tracking_unit_id ? "Memperbarui" : "Tambahkan";
    },
  },
  methods: {
    onClose() {
      if (this.isDirty) {
        this.showCancelModal = true;
      } else {
        this.doClose();
      }
    },
    doClose() {
      this.showCancelModal = false;
      this.$emit("close");
    },
    onSelectDayChange(event) {
      if (event.target.value === "custom") {
        this.startDate = null;
        this.startHour = "";
        this.startMinute = "";
        this.endDate = null;
        this.endHour = "";
        this.endMinute = "";
      } else {
        var now = dayjs();
        var later = now.subtract(Number(event.target.value), "day");
        this.endDate = now.toDate();
        this.endHour = doubleDigitFormat(now.hour().toString());
        this.endMinute = doubleDigitFormat(now.minute().toString());
        this.startDate = later.toDate();
        this.startHour = doubleDigitFormat(later.hour().toString());
        this.startMinute = doubleDigitFormat(later.minute().toString());
      }
    },
    delete() {
      this.showDeleteModal = true;
    },
    doSave() {
      this.$emit("closeModal");
      this.$store.state.showLoadingTrack = true;
      const callback = !!this.tracking_unit_id
        ? postEditTracking
        : postAddTracking;
      callback(this.inputData).then(({ data: { result, code, message } }) => {
        this.loading = false;
        if (code === 200) {
          this.$emit("onSave", result);
          this.$store.state.showLoadingTrack = false;
        } else {
          this.$store.state.showLoadingTrack = true;
          this.$toast.error(message);
        }
      });
    },
    onConfirmDelete() {
      postRemoveTracking(this.inputData).then(
        ({ data: { result, code, message } }) => {
          this.loading = false;
          this.showDeleteModal = false;
          if (code === 200) {
            this.$emit("onDelete", result);
          } else {
            this.$toast.error(message);
          }
        }
      );
    },
    fetchDeviceUnitList() {
      getDeviceUnit().then(({ data: { code, result, message } }) => {
        this.deviceList = result;
      });
    },
  },
  mounted() {
    this.user_id = localStorage.user_id;
    this.fetchDeviceUnitList();
  },
};
</script>
<style>
.time-separator {
  font-size: 20pt;
  margin-left: 6px;
  margin-right: 6px;
}
</style>
