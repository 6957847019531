<template lang="pug">
div
  device-form-modal(
    ref="form"
    @close="showAdd=false"
    :visible="showAdd"
    :currentDevice="currentDevice"
    @onSave="onDeviceChanged"
    @onDelete="onDeviceDelete"
    )
  card(title='Reports')
    div.row
      .col-12
        .d-flex.justify-content-end.mb-5
          matador-search#search-box(placeholder='Cari Imei' autocomplete='off')
          matador-button.py-2.px-4.ml-2(icon="fa fa-sign-in" :outline="true" @click="onExport") Export
        keep-alive
          .table-responsive
            bs-table(
              ref='table'
              :columns="table.columns",
              :options="table.options",
              :data="devices",
              @on-post-body="vueFormatterPostBody"
            )
</template>
<script>
import tableMixin from "@/mixins/table";
import ChevronDown from "vue-material-design-icons/ChevronDown.vue";
import ChevronUp from "vue-material-design-icons/ChevronUp.vue";
import Plus from "vue-material-design-icons/Plus.vue";
import EyeOutline from "vue-material-design-icons/EyeOutline.vue";
import EyeOffOutline from "vue-material-design-icons/EyeOffOutline.vue";
import Dropdown from "@/components/Dropdown.vue";

import axios from "axios";

import { getReportedList } from "@/utils/api";

export default {
  mixins: [tableMixin],

  components: {
    ChevronDown,
    ChevronUp,
    EyeOutline,
    EyeOffOutline,
    Plus,
  },

  data() {
    return {
      currentDevice: {},
      position: "",
      inputData: {
        username: "",
        fullname: "",
        email: "",
        position: "",
        organization: "",
        password: "",
        password_confirm: "",
      },
      showAdd: false,
      devices: [],
      organizationList: [],
      hidepassword: true,
      hideconfirmpassword: true,
      showDeleteModal: false,
      perPage: 10,
      table: {
        options: {
          ajax: this.fetchDeviceList,
          search: true,
          pagination: true,
          showSearchButton: true,
          searchSelector: "#search-box",
          pageSize: this.perPage,
          searchSelector: "#search-box",
          sortable: true,
          sortDesc: true,
          paginationHAlign: "center",
          paginationDetailHAlign: "right",
          paginationParts: ["pageInfo", "pageList"],
          classes: ["table", "table-hover"],
          loadingFontSize: 16,
          sidePagination: "server",
        },
        columns: [
          {
            title: "Nomor Polisi",
            field: "vin",
            sortable: true,
            sortDesc: true,
            searchable: true,
            widthUnit: "%",
            width: "22",
          },
          {
            title: "Nama Perangkat",
            field: "description",
            sortable: true,
            sortDesc: true,
            searchable: true,
            widthUnit: "%",
            width: "22",
          },
          {
            title: "Jenis Kendaraan",
            field: "vehicle_type_name",
            sortable: true,
            sortDesc: true,
            searchable: true,
            widthUnit: "%",
            width: "22",
          },
          {
            title: "Jam",
            field: "label",
            sortable: true,
            sortDesc: true,
            searchable: true,
            widthUnit: "%",
            width: "22",
          },
          {
            title: "Bahan bakar",
            field: "total",
            sortable: true,
            sortDesc: true,
            searchable: true,
            widthUnit: "%",
            width: "22",
            formatter: (value) => {
              return `${value} L`;
            },
          },
          {
            title: "Jarak Ditempuh",
            field: "distance",
            sortable: true,
            sortDesc: true,
            searchable: true,
            widthUnit: "%",
            width: "22",
            formatter: (value) => {
              return `${value} m`;
            },
          },
        ],
      },
    };
  },
  methods: {
    customSort(sortName, sortOrder, data) {
      var order = sortOrder === "desc" ? -1 : 1;
      data.sort(function (a, b) {
        var aa = +(a[sortName] + "").replace(/[^\d]/g, "");
        var bb = +(b[sortName] + "").replace(/[^\d]/g, "");
        if (aa < bb) {
          return order * -1;
        }
        if (aa > bb) {
          return order;
        }
        return 0;
      });
    },
    clickAdd() {
      this.currentDevice = null;
      this.showAdd = true;
    },
    clickEdit(device) {
      this.currentDevice = device;
      this.showAdd = true;
    },
    clickDelete(device) {
      this.currentDevice = device;
      this.$refs.form.delete();
    },
    async onExport() {
      try {
        const date = "2024-09-12"; // atau bisa diambil dari state atau input date picker
        const unit_id = "unit-1722607166508"; // bisa juga diganti dengan dynamic unit_id dari form
        const type = "hourly";

        const url = `https://api-my.matadortracker.com/v3/vehicle/export`;

        // Request ke API menggunakan axios
        const response = await axios.get(url, {
          responseType: "blob", // Untuk mendownload file
        });

        // Buat link untuk mendownload file dari response
        const blob = new Blob([response.data], {
          type: "application/octet-stream",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `Report.csv`; // Nama file bisa disesuaikan
        link.click();
      } catch (error) {
        console.error("Export failed:", error);
        alert("Export gagal. Silakan coba lagi.");
      }
    },
    fetchDeviceList(params) {
      const responseHandler = ({ data, status }) => {
        if (status !== 200) throw new Error("Failed to load list device");

        this.devices = data.result.data;
        let pagination = data.result.pagination;

        if (!this.devices || !this.devices.length) return params.success(false);
        let resource = {
          rows: this.devices,
          total: pagination.total_data || 0,
          totalNotFiltered: pagination.total_data || 0,
        };

        setTimeout(() => params.success(resource), 500);
      };

      let page = params.data.offset / params.data.limit || 0;
      let payload = {
        limit: params.data.limit || 10,
        page: page + 1,
        search: params.data.search,
      };

      getReportedList(payload)
        .then(responseHandler)
        .catch((e) => {
          let err = e.response ? e.response.data : e;
          this.$toast.error(err.message || "Failed to load user list");
        });
    },
    onDeviceChanged(device) {
      this.showAdd = false;
      this.$refs.table.refresh();
      this.$toast.open("Successfully save device data.");
    },
    onDeviceDelete() {
      this.$refs.table.refresh();
      this.$toast.open("Successfully delete device data.");
    },
  },
  mounted() {
    // reset search if clear
    var that = this;
    window.$(".search input").on("search", function () {
      if (!window.$(this).val()) {
        that.$refs.table.resetSearch();
      }
    });
  },
};
</script>
<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.header {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
}
.content-filter {
  margin-top: -1rem;
}
.tab-filter {
  min-width: 200px;
  border-right: 2px solid #eaeaec;
  padding-right: 0.5rem;
}
.badge-circle {
  border-radius: 50%;
  width: 26px;
  height: 26px;
  padding: 4px;

  text-align: center;

  font: 10pt Arial, sans-serif;
  font: 10pt Arial, sans-serif;
}
.p-viewer {
  float: right;
  margin-right: 10px;
  margin-top: -33px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
</style>
