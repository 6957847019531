<template lang="pug">
div#invoices
  card(v-if="!dataInvoice || dataInvoice.length == 0")
    div(v-if="!dataInvoice")
      p.text-center.mb-0 Loading the invoice, please wait...
    div(v-else)
      p.text-center.mb-0 Invoice not found
  card(v-else)
    .row.justify-content-between
      .col-lg-6.col-md-12.col-sm-12
        .matador-h4.matador-text-01 Invoice 
      .col-lg-6.col-md-12.col-sm-12
        .d-flex.justify-content-center
          div.mr-1(style="padding:0px 0px !important" v-if="this.auth.role == 'superadmin' || this.auth.role =='admin' || this.auth.role=='staff' ")
              b-button.btn.btn-outline-primary(v-show="this.dataInvoice.payment_status!='Paid'" @click="offlineModal()") 
                span.mdi.mdi-receipt
                span.pl-1 Offline Payment
              b-button.btn(style="backgroundColor: #D3D4D8 !important; border:1px solid #D3D4D8 !important" v-show="this.dataInvoice.payment_status=='Paid'" ) 
                span.matador-text-03.mdi.mdi-receipt
                span.matador-text-03.pl-1 Offline Payment
          div.mr-1(style="padding:0px 0px !important")
            b-button.btn.btn-outline-primary(@click="makePDF()")
              span.mdi.mdi-download
              span.pl-1 Save PDF
          div(style="padding:0px 0px !important")
              b-button.btn.btn-outline-primary(@click="sendEmail(dataInvoice.order_id)") 
                span.mdi.mdi-send
                span.pl-1 Send via email
    //- .d-flex.justify-content-between
    //-   div()
    //-     .matador-h4.matador-text-01 Invoice 
    //-   .d-flex
    //-     div.mr-2(v-if="this.auth.role == 'superadmin' || this.auth.role =='admin' || this.auth.role=='staff' ")
    //-       b-button.btn.btn-outline-primary(v-show="this.dataInvoice.payment_status!='Paid'" @click="offlineModal()") 
    //-         span.mdi.mdi-receipt
    //-         span.pl-1 Offline Payment
    //-       b-button.btn(style="backgroundColor: #D3D4D8 !important; border:1px solid #D3D4D8 !important" v-show="this.dataInvoice.payment_status=='Paid'" ) 
    //-         span.matador-text-03.mdi.mdi-receipt
    //-         span.matador-text-03.pl-1 Offline Payment
    //-     div.mr-2()
    //-       b-button.btn.btn-outline-primary(@click="makePDF()")
    //-         span.mdi.mdi-download
    //-         span.pl-1 Save PDF
    //-     div( @click="sendEmail(dataInvoice.order_id)")
    //-       b-button.btn.btn-outline-primary 
    //-         span.mdi.mdi-send
    //-         span.pl-1 Send via email
    .d-flex.mt-2.mb-2
      div(:class="{'bg-sematic':hasDanger,'bg-prima':hasSuccess,'bg-warn':hasWarning}" style="height:24px;padding-left: 8px ;padding-right: 8px; padding-top:2px;padding-bottom:2px;")
          span(:class="{'text-danger': hasDanger,'text-primary': hasSuccess,'text-warn':hasWarning}") {{this.dataInvoice.payment_status}}
    .row(v-if="this.dataInvoice.payment_status=='Paid'") 
      .col-sm-5.col-md-2.col-xs-12
        .matador-h6.matador-text-03(style="font-weight:normal") Payment Date 
      .col-sm-7.col-md-8.col-xs-12
        .matador-h6.matador-text-02 : {{this.dataInvoice.payment == undefined?'null':this.formatDate2(dataInvoice.payment.payment_date)}}
    .row.mt-2(v-if="this.dataInvoice.payment_status=='Paid'") 
      .col-sm-5.col-md-2.col-xs-12
        .matador-h6.matador-text-03(style="font-weight:normal") Payer Name 
      .col-sm-7.col-md-8.col-xs-12
        .matador-h6.matador-text-02 :  {{this.dataInvoice.payment == undefined?'null':this.dataInvoice.payment.additional_data.payer_name}}
    .row.mt-2(v-if="this.dataInvoice.payment_status=='Paid'")  
      .col-sm-5.col-md-2.col-xs-12
        .matador-h6.matador-text-03(style="font-weight:normal") Total Amount 
      .col-sm-7.col-md-8.col-xs-12
        .matador-h6.matador-text-02 : {{dataInvoice.currency}} . {{this.dataInvoice.payment == undefined?'null': this.mataUang(this.dataInvoice.payment.total_amount)}},-    
    .row.mt-2.mb-3(v-if="this.dataInvoice.payment_status=='Paid'") 
      .col-sm-5.col-md-2.col-xs-12
        .matador-h6.matador-text-03(style="font-weight:normal") Receipt 
      .col-sm-7.col-md-8.col-xs-12
        a.matador-h6(:href="dataInvoice.payment.additional_data.receipt" target="_blank") : Receipt.jpg

    div(id="printable" style="border: 1px solid #D3D4D8")
      .d-flex 
        div(style="width:100%")
        div.pt-3.mr-1(style="width:20%" v-if="this.auth.role == 'user' && this.dataInvoice.payment_status == 'Unpaid'" )
          matador-button.btn.btn-primary(@click="payInvoiceModal()") 
            .matador-text-01.pl-1.text-white pay this invoice
        div.pt-3.mr-1(style="width:20%" v-if="this.dataInvoice.payment_status=='Paid'")
            div.pt-1(style="width: 95px; height:60px;border:3px solid #992024")
              .matador-h2.pl-2(style="color:#992024") PAID
      div.mt-4(id="invoiceFor" style="")
        img.mb-2.mb-lg-0(width="160px" height="37px" style="border-top-right-radius: 12px;border-top-left-radius: 12px;" src="@/assets/img/matador.png")
        .d-flex.mt-2
          div(style="width:100%")
            .matador-h2.matador-text-01 INVOICE
          div.pt-3.mr-1(style="width:40%")
            .matador-h6.matador-text-03(style="font-weight:normal !important") Invoice for
            .matador-h6.matador-text-02.mt-2 {{this.dataInvoice.additional_data.billing_information.name}}
        .d-flex
          div(style="width:100%")
            .d-flex(style="width:70%")
              .col-lg-4.col-md-4.col-sm-12.col-xs-12(style="padding:0px 0px !important")
                .matador-text-03 Invoice No. 
              .col-lg-8.col-md-8.col-sm-12.col-xs-12
                .matador-h5.matador-text-02 : {{this.dataInvoice.invoice_id}}
            .d-flex.mt-1(style="width:70%")
              .col-lg-4.col-md-4.col-sm-12.col-xs-12(style="padding:0px 0px !important")
                .matador-text-03 Issue Date  
              .col-lg-8.col-md-8.col-sm-12.col-xs-12
                .matador-h5.matador-text-02 : {{formatDate(this.dataInvoice.issue_date)}}
            .d-flex.mt-1(style="width:70%")
              .col-lg-4.col-md-4.col-sm-12.col-xs-12(style="padding:0px 0px !important")
                .matador-text-03 Payment Plan 
              .col-lg-8.col-md-8.col-sm-12.col-xs-12
                .matador-h5.matador-text-02 : {{this.dataInvoice.payment_plan==="post-payment"?"post-paid":"pre-paid"}}
            .d-flex.mt-1(style="width:70%")
              .col-4(style="padding:0px 0px !important")
                .matador-text-03 Due Date 
              .col
                .matador-h5.matador-text-02 : {{formatDate(this.dataInvoice.due_date)}}
            .d-flex.mt-3(style="width:70%")
              .col-4(style="padding:0px 0px !important")
                .matador-text-03 Start time
              .col
                .matador-h5.matador-text-02 : {{formatDateTime(this.dataInvoice.start)}}
            .d-flex.mt-1(style="width:70%")
              .col-4(style="padding:0px 0px !important")
                .matador-text-03 Return time
              .col
                .matador-h5.matador-text-02 : {{formatDateTime(this.dataInvoice.finish)}}
            .d-flex.mt-1(style="width:70%")
              .col-4(style="padding:0px 0px !important")
                .matador-text-03 Mileage(Km)
              .col
                .matador-h5.matador-text-02 : -
          div.mr-1(style="width:40%")
            .matador-h5.matador-text-03(style="font-weight : normal !important") {{this.dataInvoice.additional_data.billing_information.address}}
      div(style="margin-top:22px;margin-right:20px;border: 1px solid #D9D9D9;border-radius:5px")  
        .d-flex.pt-1.pb-1(style="border-bottom: 1px solid #D3D4D8;background-color:#FAFAFC")
          .col-lg-1.col-md-2
            .matador-h5.matador-text-02 No
          .col-lg-3.col-md-3
            .matador-h5.matador-text-02 Item Description
          .col-lg-2.col-md-2
            .matador-h5.matador-text-02 Qty
          .col-lg-3.col-md-3
            .matador-h5.matador-text-02 Rates
          .col-lg-3.col-md-3
            .matador-h5.matador-text-02 Amount
        div(v-for="(item,index) in dataInvoice.unit" :key="index")
          .d-flex.pt-3.pb-3
            .col-lg-1.col-md-2
              .matador-text-03 {{++index}}
            .col-lg-3.col-md-3
              //- harusnya item.name//
              .matador-text-03 {{item.series}}
            .col-lg-2.col-md-2
              .matador-text-03 {{item.qty}}
            .col-lg-3.col-md-3
              .matador-text-03 {{dataInvoice.currency}} . {{mataUang(item.price_per_qty)}},-
            .col-lg-3.col-md-3
              .matador-text-03 {{dataInvoice.currency}} . {{mataUang(item.total_price)}},-
          div( v-show="index != dataInvoice.unit.length" style="padding-bottom:20px;border-bottom: 1px solid #F0F1F3;")
        .d-flex.pt-2(style="background-color:#FAFAFC")
            div(style="width:50%")
            div(style="width:20%")
              .matador-h5.matador-text-02(style="text-align:right") Total Amount :
            div(style="width:30%")
              .matador-h5.matador-text-02(style="text-align:center") {{dataInvoice.currency}} . {{mataUang(this.totalAmount)}}
        .d-flex.pt-2(style="background-color:#FAFAFC")
            div(style="width:50%")
            div(style="width:20%")
              .matador-h5.matador-text-02(style="text-align:right") VAT {{this.dataInvoice.vat}} % :
            div(style="width:30%")
              .matador-h5.matador-text-02(style="text-align:center") {{dataInvoice.currency}} . {{this.dataInvoice.amount_vat==null? 0 :mataUang(this.dataInvoice.amount_vat)}}
        .d-flex.pt-2.pb-2(style="background-color:#FAFAFC")
            div(style="width:50%")
            div(style="width:20%")
              .matador-h5.matador-text-02(style="text-align:right") Total Due :
            div(style="width:30%")
              .matador-h5.matador-text-02(style="text-align:center") {{dataInvoice.currency}}. {{mataUang(this.dataInvoice.price)}}
      div(style="margin-top:80px")
        .matador-h4.matador-text-02(style="margin-bottom:10px") Terms and Conditions :
        .matador-text-02(id="term" v-html="dataInvoice.rentalSetting.term_condition") 
        //- .matador-text-02.mt-1 * All invoices are due immediately upon receipt unless otherwise mutually agreed to. 
        //- .matador-text-02.mt-1 * Please proccess your payment by midtrans link we have attached above or transfer payment to our bank account :
        .d-flex(style="margin-top:15px")
          .col-3
            .matador-h5.matador-text-01 A/C IDR
          .col-2
            .matador-h5.matador-text-01 : {{this.dataInvoice.rentalSetting.account_number}}
        .d-flex
          .col-3
            .matador-h5.matador-text-01 In Favor of
          .col-2
            .matador-h5.matador-text-01 : {{this.dataInvoice.rentalSetting.account_name}}
        .d-flex
          .col-3
            .matador-h5.matador-text-01 Bank
          .col-2
            .matador-h5.matador-text-01 : {{this.dataInvoice.rentalSetting.bank_name}}
        .matador-h4.matador-text-02(style="margin-top:30px") Notes :
        .matador-text-02.mt-1(id="notes" v-html="this.dataInvoice.rentalSetting.notes" style="margin-top:10px !important;margin-bottom:73px;")
          
  upload-modal( 
        v-if="selectPhoto" 
        title="Select an Image" 
        :placeholder="require('@/assets/img/car-placeholder-square.png')"
        :ratio="1.65"
        @selected="onSelectPhoto" 
        @close="selectPhoto = false" 
      )
  payment-invoice-modal(
    v-if="showPaymentInvoiceModal" :dataOrder="this.dataInvoice" :price="mataUang(this.totalPrice)"
    @close="showPaymentInvoiceModal = false" 
    @success="onEtalaseChanged"
    )
  offline-payment-modal(
    v-if="showOfflinePaymentModal"
    @close="showOfflinePaymentModal = false" 
    :dataOrder="this.dataInvoice"
    @offline="onPaymentChanged"
    )
</template>
<script>

import UploadModal from "@/components/Matador/UploadModal.vue";
import PaymentInvoiceModal from "@/components/Matador/PaymentInvoiceModal.vue";
import OfflinePaymentModal from "@/components/Matador/OfflinePaymentModal.vue";
import {getDetailInvoice , postSendInvoice} from '@/utils/api'
import NotFound from "@/pages/NotFoundPage.vue";
import dayjs from 'dayjs';
import {mapGetters} from 'vuex';
export default {
  data() {
    return {
      modalTabIndex:0,
      dataInvoice: null,
      hasDanger:false,
      hasSuccess:false,
      hasWarning:false,
      totalAmount:0,
      priceVAT:0,
      totalPrice:0,
      selectPhoto: false,
      image:null,
      showPaymentInvoiceModal:false,
      showOfflinePaymentModal:false
    }
  },
  components:{
    NotFound,
    UploadModal,
    PaymentInvoiceModal,
    OfflinePaymentModal
  },
  props:['order_id'],
  computed:{
    ...mapGetters({
      auth:'gettersAuth'
    })
  },
  methods: {
    convertHTML(request){
      
      let convert=request.replace(/(<([^>]+)>)/gi, "");
      return convert;
    },
    onPaymentChanged(){
      this.getDataDetailInvoice();
      this.showOfflinePaymentModal =false;
      this.$toast.open('Successfully pay this order')
    },
    onEtalaseChanged(){
      this.$toast.open('successfully upload image')
    },
    offlineModal(){
      this.showOfflinePaymentModal=true
    },
    payInvoiceModal(){
      this.showPaymentInvoiceModal=true;
    },
    async sendEmail(params){
      try {
        // console.log(params,"ini params")
        let obj={
          order_id:params
        }
        let response = await postSendInvoice(obj)
        if(response.data.code ==200){
          this.$toast.open('Please check this Invoice to your mail');
        }else{
          this.$toast.error('Failed send email');
        }
      } catch (error) {
        
      }
    },
    onSelectPhoto(base64){
      this.image = base64;
      this.selectPhoto = false;
    },
    makePDF(){
      this.$router.push('/pdfInvoice/'+this.order_id)
    },
    countTotalAmount(){
      var totalAmount=0
      for (let index = 0; index < this.dataInvoice.unit.length; index++) {
        totalAmount=totalAmount+this.dataInvoice.unit[index].total_price
      }
      this.totalAmount=totalAmount;
      // console.log(this.totalAmount,"totalAmount")
      this.countVAT();
    },
    countVAT(){
      this.priceVAT=Math.floor((this.dataInvoice.vat*this.totalAmount)/100);
      // console.log(this.priceVAT,"init price vat")
      this.countTotalPrice()
    },
    countTotalPrice(){
      this.totalPrice=this.totalAmount+this.priceVAT;
      // console.log(this.totalPrice,"ini total Price")
    },
    mataUang(req){
      var	number_string = req.toString();
      var sisa 	= number_string.length % 3
      var rupiah 	= number_string.substr(0, sisa)
      var ribuan 	= number_string.substr(sisa).match(/\d{3}/g)
          
      if (ribuan) {
        var separator = sisa ? '.' : '';
        rupiah += separator + ribuan.join('.');
      }
      return rupiah;
    },
    formatDate(params){
      var date=dayjs(params).format('DD/MM/YYYY')
      return date
    },
    formatDate2(params){
      var date=dayjs(params).format('DD MMMM YYYY')
      return date
    },
    formatDateTime(params){
      var date=dayjs(params).format('DD/MM/YYYY HH:mm')
      return date
    },
    async getDataDetailInvoice(){
      try {
       
        let response= await getDetailInvoice({order_id:this.order_id})
        
        if(response.data.code==200){
          this.dataInvoice=response.data.result
          console.log(this.dataInvoice,"ini data invoices")
          this.countTotalAmount();
          // console.log(this.dataInvoice,"ini this. dataInvoice")
          if(this.dataInvoice.payment_status=="Unpaid"){
            this.hasWarning=true;
            this.hasDanger=false;
            this.hasSuccess=false;

          }else if(this.dataInvoice.payment_status=="Paid"){
            this.hasSuccess=true;
            this.hasDanger=false;
            this.hasWarning=false;
          }else if(this.dataInvoice.payment_status=="Over Due"){
            this.hasDanger=true;
            this.hasSuccess=false;
            this.hasWarning=false;
          }else{
            this.hasWarning=false;
            this.hasDanger=false;
            this.hasSuccess=false;
          }
        }
        else if(response.data.code==500){
          this.dataInvoice=[]
        }
      } catch (error) {
        console.log(error)
      }
    }
  },
  mounted() {
    
    this.getDataDetailInvoice()
  },
}
</script>
<style >
#printable{
  padding-left:40px;
}
#invoiceFor{
  padding-left:40px;
}
@media(max-width: 786px){
 #printable{
  padding-left:20px;
  }
#invoiceFor{
  padding-left:20px;
  } 
}
</style>