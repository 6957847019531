<template lang="pug">
transition(name="modal")
  div
    .modal-mask(v-if="visible")
      .modal-wrapper
        .modal-dialog(role="document")
          .modal-content
            .modal-header
              .h5.font-weight-bold.card-title.px-3 {{ title }} User Privilage
              button.close(
                type="button",
                data-dismiss="modal",
                aria-label="Close",
                @click="onClose() "
              )
                span.pr-3(aria-hidden="true", @click="showAdd = false") &times;
            .modal-body
                form.px-3.mt-3
                  .form-group
                    label Full Name
                    select.custom-select.form-control(type='text',v-model="user_id"
                     :class="{ 'matador-placeholder': user_id === ''}")
                      option(value="" selected disabled) Insert Full Name
                      option(v-for="item in userList" :value="item.user_id") {{item.name}}
                  .form-group
                    label Email
                    input.form-control(readonly v-model="selectedUser.email")
                  .form-group
                    label Position
                    input.form-control(readonly v-model="selectedUser.position")
                  .form-group
                    label Device Name
                    multiselect(
                      v-model="selectedDevice",
                      track-by="device_unit_id",
                      :multiple="true",
                      label="description",
                      placeholder="Choose Device Name",
                      :options="deviceList",
                      :searchable="true",
                      :allow-empty="false",
                      :closeOnSelect="false"
                    )
                div.float-right.mt-3
                  button.btn.btn-link.mr-3(type="button" @click="onClose() ") Cancel
                  matador-button(:disabled="!isValid" @click="doSave") {{ buttonTitle }}
      confirmation-modal(
        v-if="showCancelModal"
        @close="showCancelModal = false" 
        @confirm="doClose()" 
        title="Buang Perubahan"
        confirm-text="Ya, Buang"
      )
        p.m-0
          | Apakah Anda yakin ingin membuang perubahan yang Anda buat?
    confirmation-modal(
      v-if="showDeleteModal"
      @close="showDeleteModal = false" 
      @confirm="onConfirmDelete" 
      title="Delete Data"
      confirm-text="Yes, Delete"
    )
      p.m-0
        | Do yo want to delete this data?
</template>

<script>
import * as yup from "yup";
import Plus from "vue-material-design-icons/Plus.vue";
import EyeOutline from "vue-material-design-icons/EyeOutline.vue";
import EyeOffOutline from "vue-material-design-icons/EyeOffOutline.vue";
import ConfirmationModal from "@/components/Matador/ConfirmationModal";
import {
  getDeviceUnit,
  getUsersList,
  postAddUserPrivilage,
  postEditUserPrivilage,
  postRemoveUserPrivilage,
} from "@/utils/api";

let userPrevilageSchema = yup.object().shape({
  user_id: yup.string().required(),
  device_unit_id: yup.array().min(1),
  created_by: yup.string().required(),
});

export default {
  data() {
    return {
      created_by: localStorage.user_id,
      driver_id: "",
      user_id_old: "",
      user_id: "",
      device_unit_id: [],
      description: "",
      selectedDevice: [],
      userList: [],
      deviceList: [],
      showDeleteModal: false,
      showCancelModal: false,
      loading: false,
    };
  },
  watch: {
    currentPrivilage: function (newVal) {
      if (newVal) {
        this.user_id_old = newVal.user_id;
      }
      this.user_id = newVal.user_id;
      this.device_unit_id = newVal.device_unit_id;
      this.selectedDevice = this.device_unit_id
        ? this.deviceList.filter((item) =>
            this.device_unit_id.includes(item.device_unit_id)
          )
        : [];
    },
    selectedDevice: function (newVal) {
      this.device_unit_id = newVal.map((item) => item.device_unit_id);
    },
  },
  components: {
    Plus,
    EyeOutline,
    EyeOffOutline,
    ConfirmationModal,
  },
  props: ["close", "currentPrivilage", "onSave", "onDelete", "visible"],
  computed: {
    isDirty() {
      if (this.currentPrivilage && this.currentPrivilage.user_id) {
        return (
          this.currentPrivilage.user_id != this.user_id ||
          this.currentPrivilage.device_unit_id != this.device_unit_id
        );
      }
      return this.user_id || this.device_unit_id.length > 0;
    },
    selectedUser() {
      return this.user_id
        ? this.userList.find((item) => item.user_id === this.user_id)
        : {};
    },
    isValid() {
      return userPrevilageSchema.isValidSync(this.inputData);
    },
    inputData() {
      let obj = {
        user_id: this.user_id,
        device_unit_id: this.device_unit_id,
        created_by: this.created_by,
      };
      return obj;
    },
    title() {
      return !!this.user_id_old ? "Edit" : "Add";
    },
    buttonTitle() {
      return !!this.user_id_old ? "Update" : "Add";
    },
  },
  methods: {
    onClose() {
      if (this.isDirty) {
        this.showCancelModal = true;
      } else {
        this.doClose();
      }
    },
    doClose() {
      this.showCancelModal = false;
      this.$emit("close");
    },
    onAddOperator(newVal) {
      this.networkList = [{ name: newVal }, ...this.networkList];
      this.network_provider = newVal;
    },
    delete() {
      this.showDeleteModal = true;
    },
    doSave() {
      this.loading = true;
      const callback = !!this.user_id_old
        ? postEditUserPrivilage
        : postAddUserPrivilage;
      callback(this.inputData).then(({ data: { result, code, message } }) => {
        this.loading = false;
        if (code === 200) {
          this.$emit("onSave", result);
        } else {
          this.$toast.error(message);
        }
      });
    },
    onConfirmDelete() {
      postRemoveUserPrivilage(this.inputData).then(
        ({ data: { result, code, message } }) => {
          this.loading = false;
          this.showDeleteModal = false;
          if (code === 200) {
            this.$emit("onDelete", result);
          } else {
            this.$toast.error(message);
          }
        }
      );
    },
    fetchUserList() {
      getUsersList().then(({ data: { code, result, message } }) => {
        this.userList = result.data;
      });
    },
    fetchDeviceUnitList() {
      getDeviceUnit().then(({ data: { code, result, message } }) => {
        this.deviceList = result;
      });
    },
  },
  mounted() {
    this.fetchUserList();
    this.fetchDeviceUnitList();
  },
};
</script>
