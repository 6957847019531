<template lang="pug">
  .geofence-card
    google-map.geofence-map(
      :ref="'geofenceMap'+geofence.geofence_id"
      :zoom="12"
      :options="mapOptions"
    )
      google-map-polygon(
        :paths="geofence.coordinate[0].map(([lat, lng]) => ({ lat, lng }))",
        :strokeColor="geofence.color"
        :fillColor="geofence.color"
        :fillOpacity="0.7"
      )
    //- p {{ [0] }}
</template>

<script>
export default {
  data: () => ({
    mapOptions: {
      draggable: true,
      fullscreenControl: false
    }
  }),
  watch: {
    geofence: {
      immediate: true,
      handler: function(newVal){
        // console.log(newVal);
        (() => {
          var bounds = new google.maps.LatLngBounds();
          ([1500, 3000]).forEach((ms) => {
            var i;
            var polygonCoords = newVal.coordinate[0].map(([ lat, lng ]) => new google.maps.LatLng(lat, lng));

            for (i = 0; i < polygonCoords.length; i++) {
              bounds.extend(polygonCoords[i]);
            }
            setTimeout(() => {
              try {
                this.$refs['geofenceMap'+newVal.geofence_id].$_getMap().then((map) => {
                  map.setCenter({lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng()})
                  map.fitBounds(bounds);
                  map.setZoom(map.zoom + .5);
                });
              } catch (error) {
                
              }
            }, ms);
          });
        })();
      }
    }
  },
  props: ['geofence']
}
</script>

<style lang="scss">
.geofence-card {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 1rem;
  .geofence-map {
    width: 100%;
    height: 12rem;
  }
}
</style>