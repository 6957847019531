<template lang="pug">
div#monitoring
  card(title='Monitoring')
    .d-flex.mb-4
      div.w-100.pr-4
        matador-search#search-button(@input="onSearch" placeholder='Search' autocomplete='off')
        //- input.w-100.h-100.form-control.search-input.border-0(style="background: #fafafc url(/icons/search-icon.png) no-repeat right 10px center;background-size: 26px;" type='search' v-model="search" placeholder='Search Monitoring' autocomplete='off')
      .p-0
        //- matador-button.py-2(size="lg") Show : All Statuses
        b-dropdown#dropdown-1.my-0(style='width: 200px' :text='"Show : "+{"all": "All Status", "true": "On", "idle":"Idle","false": "Off"}[status]' variant='primary' size="md")
          b-dropdown-item(@click="status = 'all'") Vehicle Status: All status
          b-dropdown-item(@click="status = 'true'") Vehicle Status: On
          b-dropdown-item(@click="status = 'idle'") Vehicle Status: Idle
          b-dropdown-item(@click="status = 'false'") Vehicle Status: Off
    loader(:loading="loading")
      p.text-center(v-if="devices.length == 0") No data available
      .row
        .col-12.col-md-4(v-for="(device, index) in shownDevices" :key="device.device_id" @mouseenter="hoveredDevice = device")
          .card.card-monitoring.shadow-sm
            .card-body
              .monitoring-img
                img.mb-2.mb-lg-0(width="100%" style="border-radius: 12px" v-if="device.device_unit[0].unit[0].image" :src="device.device_unit[0].unit[0].image | assets")
                img.mb-2.mb-lg-0(width="100%" style="border-radius: 12px" v-if="!device.device_unit[0].unit[0].image" src="@/assets/img/trucks.png")
                dropdown-monitoring(tag="a" class="dropdown-toggle-no-caret" icon="mdi mdi-24px mdi-dots-vertical" style="position:absolute;right:0px !important;top:7px;")
                  li(class="pl-3 pr-3 pt-2 pb-2 matador-text-03" style="z-index:2000 !important" ) 
                    .d-flex.justify-content-between
                      .matador-text-02.mr-2 Reset Setting
                      div
                        button.border-0.bg-transparent(style="position:relative;" title="Reset vehicle data" @click="onResetConfirmation(device)")
                          img.mr-1(width="28" style="padding-top:-20px !mportant" src="@/assets/img/reset-icon-new.png")
                  li(class="pl-3 pr-3 pt-2 pb-2 matador-text-03" style="z-index:2000 !important; " ) 
                    .d-flex.justify-content-between
                      .matador-text-02.mr-2 Relay On/Off
                      div
                        toggle-button(
                          :sync="true"
                          :margin="4"
                          :width="40"
                          :height="22" 
                          :value="device.device_unit[0].operating" 
                          :color="{'checked': '#c0f5c0', 'unchecked': '#ffe0df'}"
                          :labels="{'checked': 'On', 'unchecked': 'Off'}"
                          @change="onSwitchDevice"
                        )
              p.my-3 {{ device.device_unit[0].unit[0].vehicle_name || device.description }}
              .py-1.mb-1
                span.d-block.text-muted.mb-1 VIN
                span {{ device.device_unit[0].unit[0].vin }}
              .py-1.mb-1
                span.d-block.text-muted.mb-1 Device Name
                span {{ device.description }}
              .py-1.mb-1
                span.d-block.text-muted.mb-2 Vehicle Status
                .matador-h5.text-success(v-if="device.last_log.length !=0 &&  device.last_log[0].device_status==true && device.last_log[0].operate_status==true ") On  
                .matador-h5.text-warning(v-else-if="device.last_log.length !=0 &&  device.last_log[0].device_status==true && device.last_log[0].operate_status==false ") Idle 
                .matador-h5.text-warning(v-else-if="device.last_log.length !=0 &&  device.last_log[0].device_status==false && device.last_log[0].operate_status==true ") Idle 
                .matador-h5.text-danger(v-else-if="device.last_log.length !=0 &&  device.last_log[0].device_status==false && device.last_log[0].operate_status==false ") Off 
                //- kalo last log nya 0 otomatis langsung OFF
                .matador-h5.text-danger(v-else) Off
                //- <p v-show="value=='Shown'" class="matador-h5 text-primary">Shown</p>
                //- <p v-show="value=='Hidden'" class="matador-h5 text-danger">Hidden</p>
                //- toggle-button(
                //-   :sync="true"
                //-   :margin="4"
                //-   :width="40"
                //-   :height="22" 
                //-   :value="device.device_unit[0].operating" 
                //-   :color="{'checked': '#c0f5c0', 'unchecked': '#ffe0df'}"
                //-   :labels="{'checked': 'On', 'unchecked': 'Off'}"
                //-   @change="onSwitchDevice"
                //- )
              .pt-3
                //- matador-button.py-0(:outline="true" type="danger" :block="true" @click="onResetConfirmation(device)" icon="fa fa-history") 
                  .d-flex.align-items-center.justify-content-center
                    //- div
                    span(style="line-height: 2rem") Reset
                matador-button.py-0(@click="onFollowVehicle(device.device_unit[0].unit[0].unit_id)" :outline="false" :block="true" type="primary") 
                  .d-flex.align-items-center.justify-content-center
                    div
                      img.mr-1(width="20" src="@/assets/img/follow-vehicle-icon.png")
                    span(style="line-height: 2rem") Follow My Vehicle
      .pt-4.mt-3
        .d-flex.align-item-center.justify-content-between
          b-pagination(
            v-model="page"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="my-table"
              @change="onPageChange"
          )
          div.d-flex.align-items-center
            span.mr-2 Per&nbsp;Page: 
            b-dropdown(:text='perPage.toString()' variant='light')
              b-dropdown-item(@click="perPage = 12") 12
              b-dropdown-item(@click="perPage = 27") 27
              b-dropdown-item(@click="perPage = 52") 52
               b-dropdown-item(@click="perPage = 102") 102
  div(v-if="showMapModal")
    transition(name="modal")
      .modal-mask
        .modal-wrapper
          .modal-dialog.modal-xl(role="document")
            .modal-content
              .modal-body
                loader(:loading="loadingMapModal" :size="42")
                  div(v-if="vehicle")
                    .d-flex.mb-4.pt-2.justify-content-between.align-items-start
                      div
                        p
                          strong.mr-2 Nomor Polisi :
                          | {{ vehicle.vin }}
                        p.m-0
                          strong.mr-2 Nama Perangkat :
                          | {{ vehicle.vehicle_name }}
                      a.p-1(href="javascript:;" role="button" @click="onFollowVehicle()")
                        img(:height="20" src="@/assets/img/close.png")
                    #mapMonitoring(style="height: 65vh")
                      .h-100.w-100.d-flex.align-items-center.justify-content-center
                        p.text-center 
                          img(width="224" style="display: block;margin: -60px auto 30px auto;" src="@/assets/img/error.png")
                          strong(style="font-size: 1rem;font-weight: bolder;color: #999;") No tracking data is found. 
                          br
                          span(style="line-height: 2.25rem;") Might be the device doesn’t yet streaming data to our databases. 
                          br
                          a.text-danger(style="text-decoration: underline;" href="javascript:;" @click="showVehicleModal = true") Show vehicle detail
  div(v-if="showVehicleModal")
    vehicle-modal(:vehicle="dataVehicle" @close="showVehicleModal = false")
  div(v-if="!!willResetVehicle")
    confirmation-modal(
      :vehicle="vehicle" 
      @close="willResetVehicle = null" 
      @confirm="onResetVehicle" 
      confirm-text="Yes, Reset"
    )
      p.m-0
        | You are about to 
        strong reset realtime setting 
        | vehicle 
        strong {{ willResetVehicle.description }}
  div(v-if="showStatusModal")
    confirmation-modal(
      :vehicle="vehicle" 
      @close="showStatusModal = false" 
      @confirm="onSwitchStatus" 
      :confirm-text='hoveredDevice.device_unit[0].operating ? "Yes, Turn Off" : "Yes, Turn On"'
    )
      p.m-0
        | You are about to 
        strong {{ hoveredDevice.device_unit[0].operating ? 'turn off ' : 'turn on ' }}
        | vehicle 
        strong {{ hoveredDevice.device_unit[0].unit[0].unit_id }}
        | . Do you want to proceed?
</template>
<script>
import DropdownMonitoring from "@/components/DropdownMonitoring.vue";
import ChevronDown from "vue-material-design-icons/ChevronDown.vue";
import ChevronUp from "vue-material-design-icons/ChevronUp.vue";
import produce from "immer";
import VehicleModal from "@/components/Matador/VehicleModal";
import ConfirmationModal from "@/components/Matador/ConfirmationModal";
import {
  getMonitoring,
  getVehicleDetail,
  postResetMonitoring,
} from "@/utils/api";
import { postSetDeviceStatus } from "../../../utils/api";
export default {
  components: {
    DropdownMonitoring,
    ChevronDown,
    ChevronUp,
    VehicleModal,
    ConfirmationModal,
  },
  watch: {
    search: {
      handler: function (newVal) {
        typeof timeout != "undefined" && clearTimeout(timeout);
        window.timeout = setTimeout(() => {
          this.getMonitoring();
        }, 500);
      },
      deep: true,
    },
    perPage: {
      handler: function (newVal, oldVal) {
        if (newVal != oldVal) {
          this.page = 1;
        }
      },
    },
    status: {
      handler: function (newVal, oldVal) {
        this.getMonitoring();
      },
    },
  },
  computed: {
    shownDevices() {
      const devices = JSON.parse(JSON.stringify(this.devices)).filter(
        (item) => {
          return (
            (item.description || "")
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) >= 0 ||
            (item.device_unit[0].unit[0].vin || "")
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) >= 0 ||
            (item.device_unit[0].unit[0].vehicle_name || "")
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) >= 0
          );
        }
      );

      return devices;
    },
    totalRows() {
      if (this.pagination && this.pagination.total_data) {
        return this.pagination.total_data;
      } else {
        return this.devices.length;
      }
    },
    dataVehicle() {
      return this.vehicle;
    },
  },
  data() {
    return {
      cobalatitude: [
        "16.875913",
        "16.875913",
        "16.975913",
        "16.975913",
        "17.00000",
      ],
      cobalongitude: [
        "96.026907",
        "96.026907",
        "96.126907",
        "96.226907",
        "96.326907",
      ],
      interval: null,
      page: 1,
      map: null,
      latitude: null,
      longitude: null,
      showMapModal: false,
      showVehicleModal: false,
      showStatusModal: false,
      loadingMapModal: false,
      willResetVehicle: null,
      loading: false,
      vehicle: null,
      perPage: 10,
      search: "",
      devices: [],
      status: "true",
      statusChanged: false,
    };
  },
  methods: {
    customSort(sortName, sortOrder, data) {
      var order = sortOrder === "desc" ? -1 : 1;
      data.sort(function (a, b) {
        var aa = +(a[sortName] + "").replace(/[^\d]/g, "");
        var bb = +(b[sortName] + "").replace(/[^\d]/g, "");
        if (aa < bb) {
          return order * -1;
        }
        if (aa > bb) {
          return order;
        }
        return 0;
      });
    },
    onSwitchStatus() {
      const {
        imei,
        device_unit: [{ operating }],
      } = this.hoveredDevice;
      this.showStatusModal = false;
      postSetDeviceStatus({
        imei,
        status: operating ? "off" : "on",
      }).then(({ data }) => {
        this.$toast.success(
          "Successfully switch operating status to: " +
            (operating ? "off" : "on")
        );
        this.loading = true;
        this.getMonitoring();
      });
    },
    onSearch(value) {
      this.search = value;
    },
    onSwitchDevice() {
      this.showStatusModal = true;
    },
    noop() {
      this.willResetVehicle = null;
    },
    onResetConfirmation(device) {
      this.willResetVehicle = device;
    },
    onResetVehicle() {
      postResetMonitoring({
        imei: this.willResetVehicle.imei,
        action: "setReset(3)",
      }).then(({ data: { code } }) => {
        code == 200 && this.$toast.open("Successfully reset the vehicle data");
      });
      this.willResetVehicle = null;
    },

    getMonitoring(params = {}) {
      params = {
        ...params,
        limit: this.limit || 12,
        page: params.page || 1,
        search: encodeURIComponent(this.search),
        status: this.status,
      };

      this.loading = true;

      getMonitoring(params).then(({ data }) => {
        const devices = data.result.data
          .map((item) => {
            return produce(item, (draft) => {
              draft.expanded = false;
            });
          })
          .filter((device) => device.device_unit.length > 0);

        if (devices.length === 0 && !this.statusChanged) {
          // Jika tidak ada data dan status belum diubah
          this.status = "all";
          this.statusChanged = true; // Set variabel statusChanged menjadi true
        } else {
          const isSameDevices =
            JSON.stringify(devices) === JSON.stringify(this.devices);

          if (!isSameDevices) {
            this.devices = devices;
          }
        }

        this.pagination = data.result.pagination;
        this.loading = false;
      });
    },

    onPageChange(newPage) {
      this.page = newPage;
      this.getMonitoring({ page: newPage });
    },
    onFollowVehicle(unit_id) {
      this.loadingMapModal = true;
      let count = 0;
      if (this.showMapModal) {
        clearInterval(this.interval);
        this.showMapModal = false;
        count = 0;
      } else {
        this.showMapModal = true;

        // getVehicleDetail({ unit_id }).then(({ data: { result } }) => {
        // this.vehicle = result;
        // console.log(this.vehicle,"ini vehicle")
        // this.loadingMapModal = false;
        // var log = this.vehicle.device_unit.device.last_log_data
        // this.latitude=log.latitude;
        // this.longitude=log.longitude;
        // });
        // setTimeout(this.initMap, 250);
        this.interval = setInterval(() => {
          getVehicleDetail({ unit_id }).then(({ data: { result } }) => {
            this.loadingMapModal = false;
            this.vehicle = result;
            var log = this.vehicle.device_unit.device.last_log_data;
            if (log != undefined) {
              this.latitude = log.latitude;
              this.longitude = log.longitude;
              if (count >= 1) {
                this.vehicleMakerMap();
              } else {
                setTimeout(this.initMap, 350);
                /*dummy */
                // setTimeout(() => {
                // console.log("ini count pertama",count)
                // setTimeout(this.initMap(count), 350);
                // this.vehicleMakerMap(count);
                // }, 350);
              }
              count = count + 1;
            } else {
              clearInterval(this.interval);
              this.$toast.error("Last log data is not found");
            }
          });
        }, 3000);
      }
    },
    toggleRow(index) {
      this.loading = true;
      let devices = JSON.parse(JSON.stringify(this.devices));
      devices[this.page * this.perPage - this.perPage + index].expanded =
        !devices[this.page * this.perPage - this.perPage + index].expanded;
      this.devices = devices;
      this.loading = false;
    },
    /* dummy */
    // vehicleMakerMap(index){
    //   console.log(index,"index pertama bos")
    vehicleMakerMap() {
      const { vehicle } = this;
      let image_truck = null;
      const log = vehicle.device_unit.device.last_log_data;
      if (log.device_status == true && log.operate_status == true) {
        image_truck = require("@/assets/img/truck-on.png");
      } else if (log.device_status == false && log.operate_status == true) {
        image_truck = require("@/assets/img/truck-idle.png");
      } else if (log.device_status == true && log.operate_status == false) {
        image_truck = require("@/assets/img/truck-idle.png");
      } else {
        image_truck = require("@/assets/img/truck-off.png");
      }
      // let image_truck=log.device_status?require("@/assets/img/truck-on.png"): require("@/assets/img/truck-off.png")
      // console.log("ini log latitute", log.latitude)
      // console.log("ini log longitute", log.longitude)
      const image = {
        url: image_truck,
        size: new window.google.maps.Size(200, 200),
        // size: new window.google.maps.Size(68, 152),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(0, 32),
        scaledSize: new google.maps.Size(30, 30),
      };
      /* dummy */
      // var latitude=this.cobalatitude[index]
      // var longitude=this.cobalongitude[index]
      // console.log("latitude,longitude",latitude,longitude)
      let marker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(
          /*dummy */
          // latitude,
          // longitude
          this.latitude,
          this.longitude
        ),
        map: this.map,
        icon: image,
        unitId: Math.random(),
      });
      window.google.maps.event.addListener(
        marker,
        "click",
        function (event) {
          this.getMonitoring();
          this.showVehicleModal = true;
        }.bind(this)
      );
      setTimeout(() => {
        marker.setMap(null);
      }, 3100);
    },
    initMap() {
      const { vehicle } = this;
      const log = vehicle.device_unit.device.last_log_data;
      if (log) {
        var initLatLng = new window.google.maps.LatLng(
          log.latitude,
          log.longitude
        );
        var mapOptions = {
          zoom: 15,
          scrollwheel: false, // we disable de scroll over the map, it is a really annoing when you scroll through page
          mapTypeControl: true,
          scaleControl: true,
          streetViewControl: false,
          center: initLatLng,
          mapTypeControlOptions: {
            position: window.google.maps.ControlPosition.TOP_RIGHT,
          },
        };
        this.map = new window.google.maps.Map(
          document.getElementById("mapMonitoring"),
          mapOptions
        );
        this.vehicleMakerMap();
        /* original bro */
        // const image = {
        //   url: image_truck,
        //   size: new window.google.maps.Size(200, 200),
        //   // size: new window.google.maps.Size(68, 152),
        //   origin: new window.google.maps.Point(0, 0),
        //   anchor: new window.google.maps.Point(0, 32),
        //   scaledSize: new google.maps.Size(30, 30),
        // };
        // const marker = new window.google.maps.Marker({
        //   position: new window.google.maps.LatLng(
        //     log.latitude,
        //     log.longitude
        //   ),
        //   map: map,
        //   icon: image,
        //   unitId: Math.random(),
        // });
        // window.google.maps.event.addListener(
        //   marker,
        //   "click",
        //   function (event) {
        //     this.getMonitoring();
        //     this.showVehicleModal = true;
        //   }.bind(this)
        // );
      }
    },
  },
  mounted() {
    this.getMonitoring();
    // this.onFollowVehicle('unit-2227');
    // this.onFollowVehicle('unit-2192');
  },
};
</script>
<style lang="scss">
@import "../../../assets/sass/paper/variables.scss";
#monitoring {
  .material-design-icon {
    > .material-design-icon__svg {
      width: 1.4rem;
      height: 1.4rem;
    }
  }
  .card.card-monitoring {
    border: 1px solid rgba(0, 0, 0, 0.015);
    .monitoring-img {
      position: relative;
      button {
        position: absolute;
        right: 0;
        top: 7px;
      }
    }
  }
  .device-list {
    a.text-dark {
      &:hover {
        background-color: #fbfbfb;
      }
    }
    td {
      .btn-sm {
        text-transform: none;
        border-radius: 5px;
        border-width: 2px;
        &.btn-outline-danger {
          &:hover,
          &:active,
          &:visited,
          &:focus {
            background-color: #fff !important;
            color: $danger-color;
          }
        }
      }
    }
  }
}
</style>
