<template lang="pug">
transition(name="modal")
  div
    .modal-mask(v-if="visible")
      .modal-wrapper
        .modal-dialog(size="sm" role="document")
          .modal-content.rounded-2
            .modal-header
              .h5.font-weight-bold.card-title.px-3  {{ title }} User
              button.close(
                type="button",
                data-dismiss="modal",
                aria-label="Close"
                @click="onClose()"
              )
                span.pr-3(aria-hidden="true") &times;
            
            .modal-body
              loader(:loading="loading")
                form.px-3.mt-3
                  .form-group
                    label(for='username') Nama Belakang
                    input#username.form-control(type='text' placeholder='Masukkan nama pengguna', v-model="username")
                  .form-group
                    label(for='fullname') Nama lengkap
                    input#fullname.form-control(type='text' placeholder='Masukkan nama lengkap Anda', v-model="full_name")
                  .form-group
                    label(for='email') Email
                    input#email.form-control(type='text' placeholder='Masukkan email Anda', v-model="email")
                  .form-group(style="position: relative")
                    label(for='inputPassword') Kata sandi
                    input#inputPassword.form-control(:type='passwordType' @input="missMatch=false" v-model="password" placeholder='Kata sandi' required='')
                    a.h3(@click="togglePassword" href="javascript:;" style="position: absolute;bottom: 1px;margin: 0;right: 10px;")
                      .text-muted(v-if="passwordType === 'password'")
                        eye-off
                      .text-primary(v-if="passwordType === 'text'")
                        eye-on
                  .form-group
                    label Negara
                    .row
                      .col
                        select.custom-select.form-control(type='text' placeholder='Choose organization' v-model="country"
                        :class="{ 'matador-placeholder':country === ''}" style="color:black !important")
                          option(value="" selected disabled) Pilih Negara
                          option(v-for="item in countryList", :value="item.country_name") {{item.country_name}}
                  .form-group
                    label Posisi
                    .row
                      .col
                        select.custom-select.form-control(type='text' placeholder='Pilih posisi Anda',v-model="position"
                          :class="{ 'matador-placeholder': position === ''}")
                          option(value="" selected disabled) Pilih posisi Anda
                          option(v-for="item in positionList" :value="item") {{item}}
                      .col-auto.pl-0
                        matador-button(iconOnly, size="sm", @click="showPositionModal=true")
                          .h3.m-0
                            plus(:size="64")
                  .form-group(v-if="this.auth.role=='superadmin'")
                    label Organization
                    .row
                      .col
                        select.custom-select.form-control(type='text' placeholder='Choose organization' v-model="organization_id"
                        :class="{ 'matador-placeholder':organization_id === ''}")
                          option(value="" selected disabled)
                          option(v-for="item in organizationList", :value="item.organization_id") {{item.name}}
                      .col-auto.pl-0
                        matador-button(iconOnly, size="sm", @click="showOrganizationModal=true")
                          .h3.m-0
                            plus(:size="64")
                  
                  div.float-right.mt-3
                    button.btn.btn-link.mr-3(type="button" @click="onClose()") Cancel
                    matador-button(:disabled="!isValid" @click="doSave") {{ buttonTitle }}
      confirmation-modal(
        v-if="showCancelModal"
        @close="showCancelModal = false" 
        @confirm="doClose()" 
        title="Buang Perubahan"
        confirm-text="Ya, Buang"
      )
        p.m-0
          | Apakah Anda yakin ingin membuang perubahan yang Anda buat?
    organization-modal(v-if="showOrganizationModal" @close="showOrganizationModal = false" @success="onAddOrganization")
    position-modal(v-if="showPositionModal" @close="showPositionModal = false" @success="onAddPosition")
    confirmation-modal(
      v-if="showDeleteModal"
      @close="showDeleteModal = false" 
      @confirm="onConfirmDelete" 
      title="Delete User"
      confirm-text="Yes, Delete"
    )
      p.m-0
        | Do yo want to delete this user?
</template>

<script>
import * as yup from "yup";
import EyeOff from "vue-material-design-icons/EyeOff.vue";
import EyeOn from "vue-material-design-icons/Eye.vue";
import Plus from "vue-material-design-icons/Plus.vue";
import EyeOutline from "vue-material-design-icons/EyeOutline.vue";
import EyeOffOutline from "vue-material-design-icons/EyeOffOutline.vue";
import ConfirmationModal from "@/components/Matador/ConfirmationModal";
import OrganizationModal from "./OrganizationModal.vue";
import PositionModal from "./PositionModal.vue";
import { mapGetters } from "vuex";
import {
  getOrganizationList,
  getJobList,
  postAddUser,
  postEditUser,
  postRemoveUser,
} from "@/utils/api";

let userSchemaAdd = yup.object().shape({
  email: yup.string().email(),
  username: yup.string().required(),
  // full_name: yup.string().required(),
  position: yup.string().required(),
  organization_id: yup.string().required(),
  password: yup.string().required(),
  country: yup.string().required(),
  position: yup.string().required(),
  // confirm_password: yup.string()
  //   .test('passwords', 'Passwords must match', function(value){
  //     return this.parent.password === value
  //   })
});
let userSchemaEdit = yup.object().shape({
  email: yup.string().email(),
  username: yup.string().required(),
  // full_name: yup.string().required(),
  position: yup.string().required(),
  organization_id: yup.string().required(),
  country: yup.string().required(),
  position: yup.string().required(),
  // confirm_password: yup.string()
  //   .test('passwords', 'Passwords must match', function(value){
  //     return this.parent.password === value
  //   })
});

export default {
  data() {
    return {
      country: "",
      missMatch: false,
      passwordType: "password",
      user_id: null,
      username: "",
      full_name: "",
      email: "",
      position: "",
      organization_id: "",
      password: "",
      confirm_password: "",
      hidepassword: true,
      hideconfirmpassword: true,
      positionList: [],
      organizationList: [],
      showOrganizationModal: false,
      showPositionModal: false,
      showCancelModal: false,
      showDeleteModal: false,
      loading: false,
      countryList: [
        {
          country_name: "Indonesia",
        },
        {
          country_name: "Myanmar",
        },
      ],
    };
  },
  watch: {
    currentUser: function (newVal) {
      if (newVal) {
        this.user_id = newVal.user_id;
      }
      console.log(newVal, "ini NewVal");
      // console.log(newVal)
      this.username = newVal.username;
      this.full_name = newVal.name;
      this.email = newVal.email;
      // console.log(newVal.position_id,"ini posisi");
      this.position = newVal.position;
      this.organization_id =
        this.auth.role == "admin"
          ? this.auth.organization_id
          : newVal.organization_id;

      this.country = newVal.country || "";
      console.log(this.auth.role, "ini role");
      // console.log(this.organization_id);
      /* this.password =  "";
      this.confirm_password = ""; */
    },
  },
  components: {
    Plus,
    EyeOutline,
    EyeOffOutline,
    OrganizationModal,
    PositionModal,
    ConfirmationModal,
    EyeOff,
    EyeOn,
  },
  props: ["close", "currentUser", "onSave", "onDelete", "visible"],
  computed: {
    ...mapGetters({
      auth: "gettersAuth",
    }),
    isDirty() {
      if (this.currentUser && this.currentUser.user_id) {
        return (
          this.currentUser.username != this.username ||
          this.currentUser.full_name != this.full_name ||
          this.currentUser.email != this.email ||
          this.currentUser.position != this.position ||
          this.currentUser.organization_id != this.organization_id ||
          this.currentUser.country != this.country
        );
      }
      return (
        this.username ||
        this.full_name ||
        this.email ||
        this.position ||
        this.organization_id ||
        this.password ||
        this.country
      );
    },
    isValid() {
      if (this.buttonTitle == "Update")
        return userSchemaEdit.isValidSync(this.inputData);
      return userSchemaAdd.isValidSync(this.inputData);
    },
    inputData() {
      let obj = {
        user_id: this.user_id,
        username: this.username,
        //saya komen ini karena didalam api v3 masih blm ada body key full_name,password,confirm_password
        name: this.full_name,
        email: this.email,
        position: this.position,
        organization_id: this.organization_id,
        password: this.password,
        country: this.country,
        // confirm_password: this.confirm_password
      };
      console.log(obj);
      // console.log("ini input",obj);
      return obj;
    },
    title() {
      return !!this.user_id ? "Ubah" : "Tambahkan";
    },
    buttonTitle() {
      return !!this.user_id ? "Ubah" : "Tambahkan";
    },
  },
  methods: {
    togglePassword() {
      this.passwordType = this.passwordType == "text" ? "password" : "text";
    },
    transformIdToNameOrganization() {},
    onClose() {
      if (this.isDirty) {
        this.showCancelModal = true;
      } else {
        this.doClose();
      }
    },
    doClose() {
      this.showCancelModal = false;
      (this.password = null), (this.passwordType = "password");
      this.$emit("close");
    },
    onAddOrganization(newVal) {
      this.organizationList = [{ name: newVal }, ...this.organizationList];
      this.organization_id = newVal;
    },
    async onAddPosition(newVal) {
      this.positionList = [newVal, ...this.positionList];
      this.position = newVal;
      /* for (let index = 0; index < this.positionList.length; index++) {
      if(this.positionList[index].position == newVal){
        this.position=this.positionList[index].position_id;
        console.log(this.position,"ini position")
      }
    }
    console.log(this.position) */
    },
    fetchPositionList() {
      getJobList().then(({ data: { code, result, message } }) => {
        this.positionList = result;
        console.log(this.positionList, "positionList");
        // console.log(result)
      });
    },
    fetchOrganizationList() {
      getOrganizationList().then(({ data: { code, result, message } }) => {
        this.organizationList = result;
        // console.log(this.currentUser);
      });
    },
    delete() {
      this.showDeleteModal = true;
    },
    doSave() {
      this.loading = true;
      const callback = !!this.user_id ? postEditUser : postAddUser;
      console.log(this.inputData, "ini input");
      callback(this.inputData).then(({ data: { result, code, messgae } }) => {
        this.loading = false;
        if (code === 200) {
          this.password = "";
          this.$emit("onSave", result);
        } else {
          this.password = "";
          console.log(messgae, "ini messgae");
          this.$toast.error(messgae.detail);
        }
      });
    },
    onConfirmDelete() {
      postRemoveUser(this.inputData).then(
        ({ data: { result, code, messgae } }) => {
          this.loading = false;
          this.showDeleteModal = false;
          if (code === 200) {
            this.$emit("onDelete", result);
          } else {
            console.log(messgae, "ini messgae");
            this.$toast.error(messgae);
          }
        }
      );
    },
  },
  mounted() {
    this.fetchPositionList();
    this.fetchOrganizationList();
  },
};
</script>
