<template lang="pug">
div
  .card
    .px-4.pt-4.pb-3
      h4 Pengaturan
    div.row
     .col-12  
      b-card.lining( no-body )
       b-tabs.texted(@activate-tab="onTabChanged" id="tabSetting" card vertical style)
        b-tab(
          :class="{'active': tabType == 'Color'}" title="Warna"
          
          )
         //- Color
         h5 Pilih Warna
         div#color-choose
          button.button.blue(id="theme-blue" v-bind:class="{'active': currentTheme === 'theme-blue'}" @click="switchTheme('theme-blue');onShowTheme();")
          button.button.red(id="theme-red" v-bind:class="{'active': currentTheme === 'theme-red'}" @click="switchTheme('theme-red');onShowTheme();")
          button.button.yellow(id="theme-yellow" v-bind:class="{'active': currentTheme === 'theme-yellow'}" @click="switchTheme('theme-yellow');onShowTheme();") 
          button.button.gray(id="theme-gray" v-bind:class="{'active': currentTheme === 'theme-gray'}" @click="switchTheme('theme-gray');onShowTheme();")
         center
          p.texted Pratinjau
          img.rounded-3.my-4(v-if="currentTheme=='theme-blue'" justify-center src='@/assets/img/theme/blue.png' style="width: 800px;max-width: 95%;")
          img.rounded-3.my-4(v-if="currentTheme=='theme-red'" justify-center src='@/assets/img/theme/red.png' style="width: 800px;max-width: 95%;")
          img.rounded-3.my-4(v-if="currentTheme=='theme-yellow'" justify-center src='@/assets/img/theme/yellow.png' style="width: 800px;max-width: 95%;")
          img.rounded-3.my-4(v-if="currentTheme=='theme-gray'" justify-center src='@/assets/img/theme/gray.png' style="width: 800px;max-width: 95%;")
        dialog-confirmation(v-if="showDialogTheme" @close="switchThemeCancel()" @confirm="switchThemeConfirm")

        b-tab(   
          v-if="$store.state.auth.role=='superadmin'"               
          :class="{'active': tabType == 'Logo'}" title="Logo")
         //-  logo
         h5 Logo
         div
          .upload-wrapper.py-3.px-4.bg-light
              .avatar-upload(v-show='!edit')
                .text-center.p-2
                  label(for='logo')
                    img.rounded-3(:src="dataLogo.length ? dataLogo[0].file_url : placeholder")
                .text-center.p-2
                  p Drop image file here or :
                  file-upload.btn.btn-primary(
                    extensions='jpg,jpeg,png' 
                    accept='image/png,image/jpeg' 
                    name='logo' 
                    :drop='!edit' 
                    v-model='files' 
                    @input-filter='inputFilter' 
                    @input-file='inputFile' 
                    ref='upload'
                  )
                    | Change Logo
                  b-button.text-dark(variant="outline-secondary" @click="removeLogo")
                    | Remove Logo
                  
              .avatar-edit(v-show='files.length && edit')
                .avatar-edit-image(v-if='files.length')
                  img(ref='editImage' :src='files[0].url')
                .text-center.p-4  
                  button.btn.btn-secondary(type='button' @click.prevent='$refs.upload.clear') Cancel
                  button.btn.btn-primary(type='submit' @click.prevent='editSave') Done

        dialog-confirmation(v-if="showDialogLogo" @close="showDialogLogo= false" @confirm="imageConfirm")

        b-tab( 
          v-if="$store.state.auth.role=='superadmin'"               
          :class="{'active': tabType == 'Subdomain'}" title="Subdomain")
          .row.mb-4
            .col-md-3.col-xs-1
            .col-md-6.col-xs-8
              .form-group
                .matador-h5.matador-text-02.mb-2 Organization
                select.custom-select.form-control(style="color:black !important" type='text', v-model="id_organization"
                    :class="{ 'matador-placeholder': id_organization === ''}")
                      option(value="" selected disabled) Choose Organization
                      option(v-for="item in organizationList" :value="item.organization_id") {{item.name}}
            .col-md-3
          .row.mt-4()
            .col-md-3.col-xs-1
            .col-md-6.col-xs-8
              .form-group
                .matador-h5.matador-text-02.mb-2 Subdomain
                .row
                  .col-lg-7.col-md-12.col-sm-12.col-xs-6(class="subdomain" )
                    input.form-control(v-model="subdomain" type="text" placeholder="your-website-url")
                  .col-lg-5.col-md-12.col-sm-12.col-xs-6(class="subdomain" )
                    .matador-h5.matador-text-01.pt-2(class="subdomain-text" style="font-weight:normal !important;text-align:right;") .matadortracker.com
                .row(v-if="this.statusDisabledDomain && this.subdomain!=''  ")
                  .col-lg-7.col-md-12.col-sm-12.col-xs-6
                    .matador-text-danger This Subdomain Already Taken By {{organization_name_subdomain.name}}
                .row(v-else-if="this.subdomain=='' ")
                  .col-lg-7.col-md-12.col-sm-12.col-xs-6
                    .matador-text-bg-4 Please type your subdomain
            .col-md-3
          .row.mt-4()
            .col-md-3.col-xs-1
            .col-md-6.col-xs-8
              matador-button.btn.btn-block.btn-lg.btn-primary(@click="onShowDomain()" :disabled="allowPostDomain") Save Change
            .col-md-3
        dialog-confirmation(v-if="showDialogDomain" @close="showDialogDomain = false;resetDomain()" @confirm="onSaveDomain()")
        
        b-tab( 
          v-if="$store.state.auth.role=='superadmin'"               
          :class="{'active': tabType == 'ApiKey'}" title="API Key")
         center
          h5 Setting API Key
          .d-flex.mb-2.pb-5
            div.w-100.p-2
             b-form-input#api-form.form-control(placeholder="Insert API Key" v-model="apiKeyForm" style="width:50%")
             transition(name="fade")
              img(:height="14" :width="14" v-if="apiKeyForm!==''" @click="apiClear()" src="@/assets/img/close.png" style="background:white;position:relative;left: 23%;bottom:50%")

          button.btn.btn-primary.h-25.w-25.my-3(@click="onShowApi()" :disabled="!apiKeyForm") Apply
        dialog-confirmation(v-if="showDialogApi" @close="showDialogApi = false;resetApi()" @confirm="onSaveApi()")

        b-tab(
          v-if="$store.state.auth.role=='superadmin'||$store.state.auth.role=='admin'"               
          :class="{'active': tabType == 'Role'}" title="Manajemen Peran")
         //-  role
         .d-flex.justify-content-between.align-items-center
          div
            .btn-group.flow-29.shadow-sm(role="group")
              button.btn.btn-sm.btn-light.text-uppercase.px-4(:class="{'active': roleType == 'member'}" type="button" @click="roleType = 'member'") Member
              button.btn.btn-sm.btn-light.text-uppercase.px-4(:class="{'active': roleType == 'role'}" type="button" @click="roleType = 'role'") Role
          
            .div(v-if="roleType=='member'")
              .d-flex.mb-2
                matador-search#search-box( v-model="search" placeholder='Cari Pengguna Atau Peran' autocomplete='off' style='width:80%')
                div
                  b-dropdown#dropdown-1.my-0(style='width: 20%' :text='"Show : "+{"all": "All Roles", "superadmin": "Super Admin", "admin": "Admin","staff":"Staff","user":"User","guest":"Guest"}[show]' variant='primary' size="md")
                    b-dropdown-item(style="z-index:1 !important" @click="show = 'all'") Show : All Roles
                    <!-- b-dropdown-item(@click="show = 'superadmin'" v-if="$store.state.auth.role=='superadmin'" ) Show :   Super Admin
                    b-dropdown-item(@click="show = 'admin'") Show : Admin
                    b-dropdown-item(@click="show = 'staff'") Show : Staff
                    b-dropdown-item(@click="show = 'user'") Show : User -->
                    //- b-dropdown-item(@click="show = 'guest'") Show : User

              div.p-0(style="z-index:-100 !important;max-width:100%")
                 .table-responsive
                   bs-table(
                    ref="table"
                    :columns="table.columns",
                    :options="table.options",
                    :data="dataRole",
                    @on-post-body="vueFormatterPostBody"
                    @on-search="onEvent"
                  )

            div(v-if="roleType=='role'")
              .table-responsive.pt-4
                bs-table(
                    ref="table2"
                    :columns="fieldsRole",
                    :options="tableCountOptions",
                    :data="positionRole",
                    @on-post-body="vueFormatterPostBody"
                    @on-search="onEvent"
                  )
         dialog-confirmation(v-if="showDialogRole" @close="roleReset" @confirm="roleConfirm")

                        
        b-tab(
          v-if="$store.state.auth.role=='superadmin'"
          :class="{'active': roleType == 'Location'}" title="Location")
         center
          h4 This menu sets functionality of location from google map API services
         .d-flex.justify-content-between.align-items-center.py-5
            div.mr-2
              span.texted.pl-4.pr-4 Activate google map API Services  
              toggle-button(
                :sync="true"
                :margin="4"
                :width="40"
                :height="22" 
                :value="toggleApi"
                :color="{'checked': '#c0f5c0', 'unchecked': '#ffe0df'}"
                :labels="{'checked': 'On', 'unchecked': 'Off'}"
                @change="showDialogLoc=true"
              )
         dialog-confirmation(v-if="showDialogLoc" @close="showDialogLoc=false" @confirm="geocodingConfirm")

        b-tab(:class="{'active': tabType == 'Profile'}" title="Profil" v-if="$store.state.auth.role!='guest'")
         div.row.mb-4
          .col-md-3.col-xs-2
          .col-md-6.col-xs-8
            .form-group.mt-3        
              label Nama Lengkap
              input.form-control(v-model="username" placeholder="Masukan Nama Lengkap" )
            .form-group
              label Alamat
              input.form-control(v-model="address" placeholder="Masukan Alamat" )
            .form-group
              label Negara
              .row
                .col
                  select.custom-select.form-control(type='text' placeholder='Pilih Negara' v-model="country"
                  :class="{ 'matador-placeholder':country === ''}" style="color:black !important")
                    option(value="" selected disabled) Pilih Negara
                    option(v-for="item in countryList", :value="item.country_name") {{item.country_name}}                
            .form-group
              label Nomor Telepon
              input.form-control(v-model="phone" placeholder="Masukan Nomor Telepon" )
            .form-group
              label Posisi
              .d-flex
                select.custom-select.form-control(type='text' placeholder='Pilih Posisi',v-model="position"
                  :class="{ 'matador-placeholder': position === ''}" style="color:black !important")
                  option(value="" selected disabled) Pilih Posisi
                  option(v-for="item in positionList" :value="item") {{item}}
                .col-auto.pl-0(style="right:-2%")
                  matador-button(iconOnly, size="sm", @click="showPositionModal=true")
                    .h3.m-0
                      plus(:size="64") 
 
              button.btn.btn-primary.h-5.w-100.my-5(@click="profileConfirm") Simpan dan lanjutkan
                                     
          organization-modal(v-if="showOrganizationModal" @close="showOrganizationModal = false" @success="onAddOrganization")
          position-modal(v-if="showPositionModal" @close="showPositionModal = false" @success="onAddPosition")                        
        dialog-confirmation(v-if="showDialogProfile" @close="showDialogProfile=false" @confirm="onSetupProfile()")
        
        b-tab(:class="{'active': tabType == 'Password'}" title="Kata sandi" v-if="$store.state.auth.role!='guest'" )
         
          .form-group.mb-4(style="position: relative")
           label(for='inputPassword') 
           h5(style="margin-left:37.5%")  Kata Sandi
           center
            input#inputPassword.w-25.h-50.form-control(:type='passwordType' v-model="passwords" placeholder='Masukan Kata Sandi Baru' required='')
            a.h3(@click="togglePassword" href="javascript:;" style="position: absolute;bottom: 1px;margin: 0;right: 38%;")
             .text-muted(v-if="passwordType === 'password'")
               eye-off 
             .text-primary(v-if="passwordType === 'text'")
               eye-on

          .form-group.mb-4(style="position: relative")
           label(style="align-item:left"   for='inputPassword') 
           
           h5(style="margin-left:37.5%") Ulang Kata Sandi
           center
            input#inputPasswords.w-25.h-50.form-control(:type='passwordType' v-model="confPasswords" placeholder='Masukan Ulang Kata Sandi Baru' required='')
            a.h3(@click="togglePassword" href="javascript:;" style="position: absolute;bottom: 1px;margin: 0;right: 38%;")
             .text-muted(v-if="passwordType === 'password'")
               eye-off 
             .text-primary(v-if="passwordType === 'text'")
               eye-on

          center
           a(v-if="passwords!=confPasswords&&confPasswords!=null") Passwords Not Match
           div
           button.btn.btn-primary.h-25.w-25.my-3(@click="showDialogPassword = true" :disabled="passwords!=confPasswords || !passwords || !confPasswords") Update Password
        dialog-confirmation(v-if="showDialogPassword" :password="true" @close="showDialogPassword=false"  @confirm="editPassword(passwords)")

        b-tab(   
          v-if="$store.state.auth.role=='superadmin'"               
          :class="{'active': tabType == 'Impersonate'}" title="Impersonate User Session"
        )
          .d-flex
            .form-group
                .matador-h4.matador-text-02.mb-2 Choose Organization
                select.custom-select.form-control(style="color:black !important" type='text', v-model="id_organization_impersonate"
                    :class="{ 'matador-placeholder': id_organization === ''}")
                      option(value="" selected disabled) Choose Organization
                      option(v-for="item in organizationList" :value="item.organization_id") {{item.name}}
          .d-flex.justify-content-between.align-items-center.mb-4
            .matador-h3.m-0 Impersonate User Session
            div
                matador-search#search-box-users(placeholder='Cari Pengguna' autocomplete='off')
          keep-alive
          .table-responsive
            bs-table(
              ref="usersTable"
              :columns="usersTableColumns",
              :options="usersTableOptions",
              :data="filteredUsers",
              @on-post-body="vueFormatterPostBody"
              @on-search="onEvent"
            )
      //- component(:is="tabTypeComponent")
            input.w-25.h-25.form-control(type="password" v-model="passwords" placeholder="Input New Password" required) 
            input.w-25.h-25.form-control(type="password" v-model="confPasswords" placeholder="Input Confirm Password" required)


 
</template>
<script>
import DialogConfirmation from "../../../components/Matador/Setting/DialogConfirmation.vue";

// password
import Cropper from "cropperjs";
import FileUpload from "vue-upload-component";
import tableMixin from "@/mixins/table";
import EyeOff from "vue-material-design-icons/EyeOff.vue";
import EyeOn from "vue-material-design-icons/Eye.vue";
import Plus from "vue-material-design-icons/Plus.vue";
import EyeOutline from "vue-material-design-icons/EyeOutline.vue";
import EyeOffOutline from "vue-material-design-icons/EyeOffOutline.vue";
import OrganizationModal from "../Users/OrganizationModal.vue";
import PositionModal from "../Users/PositionModal.vue";
import Dropdown from "@/components/Dropdown.vue";
import {
  getOrganizationList,
  getJobList,
  postSubdomain,
  checkSubdomain,
  getPosition,
} from "@/utils/api";
import {
  postEmailVerification,
  postProfile,
  getAllUsers,
  getNewToken,
} from "../../../utils/api";
import {
  postRemoveLogo,
  getRole,
  getLogo,
  postChangeLogo,
  postRoleUpdate,
  postApiKey,
  getApiKey,
  postChangePasswordUser,
  getGeocoding,
  postGeocoding,
} from "@/utils/api";

export default {
  mixins: [tableMixin],
  components: {
    DialogConfirmation,
    EyeOff,
    EyeOn,
    FileUpload,
    Plus,
    EyeOutline,
    EyeOffOutline,
    OrganizationModal,
    PositionModal,
  },

  data() {
    return {
      organization_name_subdomain: "",
      id_organization_impersonate: "",
      usersTableOptions: {
        search: true,
        pagination: true,
        pageSize: this.perPage,
        searchSelector: "#search-box-users",
        showSearchButton: true,
        sortable: true,
        paginationHAlign: "center",
        paginationDetailHAlign: "right",
        paginationParts: ["pageInfo", "pageList"],
        classes: ["table", "table-hover"],
      },
      usersTableColumns: [
        {
          title: "Username",
          field: "username",
          searchable: true,
          sortable: true,
          widthUnit: "em",
          width: "10",
        },
        // {
        //   title: "Fullname",
        //   field: "name",
        //   sortable: true,
        //   searchable: true,
        //   widthUnit: '%',
        //   width: '20'
        // },
        {
          field: "email",
          title: "Email",
          sortable: true,
          searchable: true,
          widthUnit: "em",
          width: "10",
        },
        {
          field: "position",
          title: "Position",
          sortable: true,
          searchable: true,
          widthUnit: "em",
          width: "10",
        },
        {
          field: "organization_name.name",
          title: "Organization",
          sortable: true,
          searchable: true,
          widthUnit: "em",
          width: "10",
        },
        {
          field: "role_name.name",
          title: "Role",
          sortable: true,
          searchable: true,
          widthUnit: "em",
          width: "7",
        },
        {
          field: "",
          title: "",
          formatter: (value, row) => {
            return this.vueFormatter({
              template: `<div class="btn-login">
                  <button :disabled="loading || row.role.length === 0 || row.role[0].name === 'superadmin'" @click="impersonate" class="btn btn-block btn-full btn-primary py-1">
                    {{ row.role.length === 0 ? 'Unknown&nbsp;role' : row.role[0].name === 'superadmin' ? 'Superadmin' : 'Login' }}
                  </button>
                </div>`,
              data: { row, value, loading: false },
              methods: {
                async impersonate() {
                  this.loading = true;
                  const {
                    data: {
                      result: { token },
                    },
                  } = await getNewToken(row.user_id);
                  const originalSession = {
                    user_id: this.$cookies.get("user_id"),
                    token: this.$cookies.get("token"),
                    role: this.$cookies.get("role"),
                  };
                  const impersonateSession = {
                    token,
                    user_id: row.user_id,
                    role: row.role[0] ? row.role[0].name : "guest",
                  };
                  this.$cookies.set("user_id", impersonateSession.user_id);
                  this.$cookies.set("token", impersonateSession.token);
                  this.$cookies.set("role", impersonateSession.role);
                  this.$cookies.set(
                    "original_session",
                    JSON.stringify(originalSession)
                  );
                  setTimeout(() => {
                    window.location.replace("/");
                  }, 1000);
                },
              },
            });
          },
          sortable: false,
          searchable: false,
          widthUnit: "em",
          width: "6",
        },
      ],
      country: this.$store.state.auth.country,
      countryList: [
        {
          country_name: "Indonesia",
        },
        {
          country_name: "Myanmar",
        },
      ],
      statusDisabledDomain: true,
      subdomainTracker: ".matadortracker.com",
      id_organization: "",
      subdomain: "",
      user_id: "",
      verification_code: "",
      username: this.$store.state.auth.name,
      address: this.$store.state.auth.address,
      phone: this.$store.state.auth.phone,
      position: this.$store.state.auth.position,
      organization_id: this.$store.state.auth.organization_id,
      positionList: [],
      organizationList: [],
      showOrganizationModal: false,
      showPositionModal: false,

      show: "all",
      toggleApi: false,
      search: "",
      password: null,
      passwordType: "password",
      showDialogTheme: false,
      showDialogLogo: false,
      showDialogApi: false,
      showDialogRole: false,
      showDialogLoc: false,
      showDialogPassword: false,
      showDialogDomain: false,
      showDialogProfile: false,
      mergeArray: [],
      userList: [],
      perPage: 10,
      table: {
        options: {
          ajax: this.getRoles,
          search: true,
          pagination: true,
          pageSize: this.perPage,
          searchSelector: "#search-box",
          pageSize: this.perPage,
          sortDesc: true,
          showSearchButton: true,
          sortable: true,
          paginationHAlign: "center",
          paginationDetailHAlign: "right",
          paginationParts: ["pageInfo", "pageList"],
          classes: ["table", "table-hover"],
          sidePagination: "server",
        },
        columns: [
          {
            title: "Username",
            field: "name",
            searchable: true,
            sortable: true,
            widthUnit: "%",
            width: "20",
          },
          {
            field: "position_id",
            title: "Role",
            searchable: false,
            sortable: true,
            widthUnit: "%",
            width: "20",
            //<option value="position-1632709715540">Super Admin</option>

            formatter: (value, row) => {
              return this.vueFormatter({
                template: `
                <b-form-select v-model=value disabled-field="notEnabled" v-on:input="roleChange(row,value)">
                  <option value="position-1652773913505">Admin</option>
                  <option value="position-1652773938094">Driver</option>
                  <option value="position-1652773922801">Guest</option>
                  <option value="position-1652773903633">Operator</option>
                  <option value="position-1652773893225">Staff</option>
                  <option value="position-1652773930322">Teltonika</option>
                  <option value="position-1652773930372">User</option>
                </b-form-select>
              `,
                data: { row, value },
                events: {
                  "click .btn": (e, value, row) => {
                    this.clickRow(row);
                  },
                },
                methods: {
                  roleChange: this.roleChange,
                },
              });
            },
          },
          {
            field: "last_login",
            title: "Last Login",
            sortable: true,
            searchable: false,
            widthUnit: "%",
            width: "15",
            formatter: (value, row) => {
              if (value) {
                const date = new Date(value);
                const day = date.getDate().toString().padStart(2, "0");

                const monthNames = [
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December",
                ];

                const month = monthNames[date.getMonth()];
                const year = date.getFullYear();
                const hours = date.getHours().toString().padStart(2, "0");
                const minutes = date.getMinutes().toString().padStart(2, "0");
                return `${day} ${month} ${year}, ${hours}:${minutes} WIB`;
              } else {
                return "";
              }
            },
          },
        ],
      },
      apiKeyFormAlive: null,
      show: "all",
      apiKeyForm: null,
      allRole: [
        {
          user_id: "",
          name: "",
          role: "{'position':'','position':''}",
          last_login: "",
        },
      ],
      dataRole: [],
      currentLogo: null,
      dataLogo: [],
      allUsers: [],
      user_id: null,
      files: [],
      file: null,
      image: null,
      edit: false,
      cropper: true,
      currentTheme: localStorage.getItem("theme-color"),
      currentThemeAlive: null,
      roleType: "member",
      roleTypes: [],
      tabType: "Logo",
      tabTypes: [
        "Color",
        "Logo",
        "Subdomain",
        "ApiKey",
        "Role",
        "Location",
        "Password",
        "Impersonate User Session",
      ],
      passwords: null,
      confPasswords: null,
      newpassword: null,
      fieldsRole: [
        {
          widthUnit: "%",
          width: "30",
          title: "Roles",
          field: "position",
          sortable: true,
        },
        {
          widthUnit: "%",
          width: "70",
          title: "Permission",
          field: "permission",
          sortable: true,
        },
        {
          widthUnit: "%",
          width: "20",
          title: "Member",
          label: "Member",
          field: "total",
          sortable: true,
        },
      ],
      positionRole: [],
    };
  },
  computed: {
    filteredUsers() {
      if (this.id_organization_impersonate != "") {
        let result = this.allUsers.filter((item) => {
          return item.organization_id == this.id_organization_impersonate;
        });
        return result;
      } else {
        return this.allUsers;
      }
    },
    allowPostDomain() {
      return this.subdomain == "" || this.id_organization == "";
    },
    tableCountOptions() {
      return {
        data: this.fetchPosition(),
        search: true,
        pagination: true,
        pageSize: this.perPage,
        searchSelector: "#search-box",
        showSearchButton: true,
        sortable: true,
        paginationHAlign: "center",
        paginationDetailHAlign: "right",
        paginationParts: ["pageInfo", "pageList"],
        classes: ["table", "table-hover"],
      };
    },
    tabTypeComponent() {
      return this.tabType.toLowerCase();
    },
  },
  watch: {
    subdomain: {
      handler: function () {
        this.checkDomain();
      },
    },
    id_organization: {
      handler: function () {
        this.checkOrganization();
        this.nameOrganizationById();
      },
    },
    pageSize: function (newVal) {
      this.$refs.table.refreshOptions();
    },
    edit(value) {
      if (value) {
        this.$nextTick(function () {
          if (!this.$refs.editImage) {
            return;
          }
          let cropper = new Cropper(this.$refs.editImage, {
            aspectRatio: this.ratio,
            viewMode: 1,
          });
          this.cropper = cropper;
        });
      } else {
        if (this.cropper) {
          this.cropper.destroy();
          this.cropper = false;
        }
      }
    },
  },
  props: ["selected", "close", "title", "placeholder", "ratio"],

  methods: {
    onTabChanged(e) {},
    checkOrganization() {
      let res = this.organizationList.filter((item) => {
        return this.id_organization == item.organization_id;
      });
      if (res[0].subdomain != null) {
        this.subdomain = res[0].subdomain.slice(
          0,
          res[0].subdomain.length - this.subdomainTracker.length
        );
        this.statusDisabledDomain = false;
      } else {
        this.statusDisabledDomain = true;
        this.subdomain = "";
      }
    },
    profileConfirm() {
      this.showDialogProfile = false;
      this.showDialogProfile = true;
    },
    togglePassword() {
      this.passwordType = this.passwordType == "text" ? "password" : "text";
    },
    onSwitchApi() {},
    removeLogo() {
      postRemoveLogo();
    },
    // imageChange(base64){
    //   // console.log(base64)
    //   this.showDialogLogo = false
    //   this.currentLogo = base64
    //   this.showDialogLogo = true
    // },
    imageConfirm() {},
    imageReset() {
      this.showDialogRole = false;
    },
    roleChange(row, value) {
      this.showDialogRole = false;
      this.showDialogRole = true;
      this.$cookies.set("role_user_id", row.user_id);
      this.$cookies.set("role_position_id", value);
    },
    roleReset() {
      this.$refs.table.refresh();
      this.showDialogRole = false;
    },
    roleConfirm() {
      postRoleUpdate({
        user_id: this.$cookies.get("role_user_id"),
        position_id: this.$cookies.get("role_position_id"),
      }).then(({ data: { result, code } }) => {
        if (code == 200) {
          this.$toast.success("Successfully Change Role");
        }
        this.showDialogRole = false;
        this.$refs.table.refresh();
        this.$emit("success", result);
        this.$emit("close");
        this.showDialogRole = false;
      });
    },
    onShowApi() {
      this.showDialogApi = false;
      this.showDialogApi = true;
    },
    onShowDomain() {
      this.showDialogDomain = false;
      this.showDialogDomain = true;
    },
    onShowTheme() {
      this.showDialogTheme = false;
      this.showDialogTheme = true;
    },
    onEvent(event) {},
    apiClear() {
      this.apiKeyForm = "";
    },
    geocodingConfirm() {
      if (this.toggleApi == false) {
        postGeocoding({ status: "on" }).then(
          ({ data: { result, code, status } }) => {
            if (code == 200) {
              this.$toast.success("Successfully Change API Service");
            }
            this.toggleApi = true;
            this.showDialogLoc = false;
            this.$emit("success", result);
            this.$emit("close");
          }
        );
      }
      if (this.toggleApi == true) {
        postGeocoding({ status: "off" }).then(({ data: { result, code } }) => {
          if (code == 200) {
            this.$toast.success("Successfully Change API Service");
          }
          this.toggleApi = false;
          this.showDialogLoc = false;
          this.$emit("success", result);
          this.$emit("close");
        });
      }
    },
    async getApi(params) {
      try {
        let resp = await getApiKey(this.$store.state.auth.user_id);
        let response = resp.data.result[0]
          ? resp.data.result[0].api_key[0]
          : "";
        this.apiKeyFormAlive = response;
        this.apiKeyForm = response;
      } catch (error) {
        // console.log(error);
      }
    },
    async getRoles(params) {
      const responseHandler = ({ data, status }) => {
        if (status !== 200) throw new Error("Failed to load role users");

        let response = data.result.data.filter(
          (res) => res.position_name != "guest"
        );

        if (this.$store.state.auth.role != "superadmin") {
          response = data.result.data.filter(
            (a) => a.position_name != "superadmin"
          );
        }

        this.userList = response;
        let pagination = data.result.pagination;

        if (!this.userList || !this.userList.length) {
          return params.success([]);
        }
        let resource = {
          rows: this.userList,
          total: pagination.total_data || 0,
          totalNotFiltered: pagination.total_data || 0,
        };

        setTimeout(() => params.success(resource), 500);
      };

      let page = params.data.offset / params.data.limit || 0;
      let payload = {
        limit: params.data.limit || 10,
        page: page + 1,
        search: params.data.search,
      };

      getRole(payload)
        .then(responseHandler)
        .catch((e) => {
          let err = e.response ? e.response.data : e;
          this.$toast.error(err.message || "Failed to load user list");
        });
    },
    async getToggle(params) {
      try {
        let resp = await getGeocoding();
        let response = resp.data.result[0] ? resp.data.result[0].status : "";
        if (response == "on") {
          this.toggleApi = true;
        } else if (response == "off") {
          this.toggleApi = false;
        }
      } catch (error) {
        // console.log(error);
      }
    },
    async getImage(params) {
      try {
        let resp = await getLogo(params);
        let response = resp.data.result;
        this.dataLogo = response;
      } catch (error) {
        // console.log(error);
      }
    },
    showPassword() {
      this.visibility = "text";
    },
    hidePassword() {
      this.visibility = "password";
    },
    editPassword(value) {
      postChangePasswordUser({
        user_id: this.$store.state.auth.user_id,
        password: value,
      }).then(({ data: { result, status, code } }) => {
        this.showDialogPassword = false;
        if (code == 200) {
          this.$toast.success("Successfully Change Password");
          this.passwords = "";
          this.confPasswords = "";
        }
        this.$emit("success", result);
        this.$emit("close");
      });
    },
    switchTheme(theme) {
      this.currentThemeAlive = this.currentTheme;
      this.currentTheme = toString(theme);
      localStorage.setItem("theme-color", theme);
      this.currentTheme = localStorage.getItem("theme-color");
    },
    switchThemeCancel() {
      this.currentTheme = this.currentThemeAlive;
      localStorage.setItem("theme-color", this.currentTheme);
      this.showDialogTheme = false;
    },
    switchThemeConfirm() {
      if (this.currentTheme != this.currentThemeAlive) {
        this.showDialogTheme = false;
        this.$store.commit("setTheme", this.currentTheme);
      } else {
        this.showDialogTheme = false;
      }
    },
    inputFile(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        this.$nextTick(function () {
          this.edit = true;
        });
      }
      if (!newFile && oldFile) {
        this.edit = false;
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          this.alert("Your choice is not a picture");
          return prevent();
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = "";
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file);
        }
      }
    },

    editSave() {
      this.edit = false;
      let oldFile = this.files[0];
      let binStr = atob(
        this.cropper.getCroppedCanvas().toDataURL(oldFile.type).split(",")[1]
      );
      let arr = new Uint8Array(binStr.length);
      for (let i = 0; i < binStr.length; i++) {
        arr[i] = binStr.charCodeAt(i);
      }
      let file = new File([arr], oldFile.name, { type: oldFile.type });
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        postChangeLogo(reader.result).then(
          ({ data: { result, code, message } }) => {
            if (code === 200) {
              this.$toast.success("Successfully Change Logo");
              this.getImage();
            } else {
              this.$toast.error("Code " + code + ": " + message);
            }
          }
        );
        this.showDialogLogo = false;
      };
    },
    resetApi() {
      this.apiKeyForm = this.apiKeyFormAlive;
      this.showDialogApi = false;
    },
    resetDomain() {
      this.subdomain = "";
      this.showDialogDomain = false;
    },
    async checkDomain() {
      let checking = this.subdomain + this.subdomainTracker;

      let response = await checkSubdomain({
        subdomain: checking.toLowerCase(),
      });
      if (response.data.result.exist) {
        this.statusDisabledDomain = true;
        this.organization_name_subdomain = response.data.result.data;
      } else {
        this.organization_name_subdomain = "";
        this.statusDisabledDomain = false;
      }
    },
    async onSaveDomain() {
      try {
        let obj = {
          subdomain: this.subdomain + this.subdomainTracker,
          organization_id: this.id_organization,
        };
        let response = await postSubdomain(obj);
        this.showDialogDomain = false;
        if (response.data.code == 200) {
          this.fetchOrganizationList();
        }
      } catch (error) {
        // console.log("subdomain error")
      }
    },

    onSaveApi() {
      postApiKey({
        user_id: this.$store.state.auth.user_id,
        api_key: this.apiKeyForm,
      }).then(({ data: { result, code } }) => {
        if (code == 200) {
          this.$toast.success("Successfully Change Api Kry");
        }
        this.showDialogApi = false;
        this.apiKeyFormAlive = result.api_key;
        this.$emit("success", result);
        this.$emit("close");
      });
    },
    toggleBackToEtalaseModal() {
      this.$store.commit(
        "setShowBackToEtalaseModal",
        !this.$store.state.showBackToEtalaseModal
      );
    },
    onAddOrganization(newVal) {
      this.organizationList = [{ name: newVal }, ...this.organizationList];
      this.organization_id = newVal;
    },
    onAddPosition(newVal) {
      this.positionList = [newVal, ...this.positionList];
      this.position = newVal;
    },
    async onSetupProfile() {
      try {
        this.showDialogProfile = false;
        let user_id = this.$store.state.auth.user_id;
        let username = this.username,
          address = this.address,
          phone = this.phone,
          position = this.position,
          organization_id = this.$store.state.auth.organization_id,
          country = this.country;
        postProfile({
          name: username,
          phone: phone,
          address: address,
          country: country,
          user_id: user_id,
          position: position,
          organization_id: organization_id,
        }).then(({ data: { code, result: userData, messgae, status } }) => {
          if (code == 200) {
            this.$toast.open("You Have Successfully Setup Profile");
          }
        });
      } catch (error) {
        this.$toast.error("Failed to Setup Profile");
        // console.log("error :",error)
      }
      this.$emit("success", this.name);
      this.$emit("close");
    },
    verifiedUser() {
      postEmailVerification({
        user_id: this.$route.query.user_id,
        email_verification_code: this.$route.query.verification_code,
      }).then(({ data: { code, result: userData, messgae } }) => {
        if (code == 200) {
        }
      });
    },

    fetchPositionList() {
      getJobList().then(({ data: { code, result, message } }) => {
        this.positionList = result;
      });
    },
    fetchOrganizationList() {
      getOrganizationList().then(({ data: { code, result, message } }) => {
        this.organizationList = result;
        // console.log(this.organizationList)
        // console.log(this.currentUser);
      });
    },
    fetchPosition() {
      getPosition().then(({ data: { code, result, message } }) => {
        if (code == 200) {
          result.forEach((access, index) => {
            result[index].position =
              result[index].position.charAt(0).toUpperCase() +
              result[index].position.slice(1);
            result[index].permission = result[index].access.join();
          });
          this.positionRole = result;
        }
      });
    },
  },
  mounted() {
    this.getToggle();
    this.getApi();
    this.getImage();
    this.fetchPositionList();
    this.fetchOrganizationList();
    this.fetchPosition();
    // var that = this;
    // window.$('.search input').on('search', function() {
    //   if(!window.$(this).val()) {
    //     that.$refs.table.resetSearch();
    //   }
    // });
  },
};
</script>
<style lang="scss">
#tabSetting__BV_tab_controls_ {
  border-width: 1px !important;
  border-right-style: solid;
  border-color: #f0f0f0 !important;
}
.texted {
  font-family: "Open Sans", sans-serif !important;
}
#color-choose {
  margin-bottom: 25px !important;
  border-width: 1px !important;
  border-bottom-style: solid;
  padding-bottom: 15px;
  border-color: #f0f0f0 !important;
}
.card.lining {
  border-width: 2px;
  // border-right-style: solid;
  border-color: gray;
  box-shadow: none;
  .nav-link {
    border-left: 10px solid transparent;
    padding-right: 30px !important;
  }
  table {
    tr {
      .btn-login {
        display: none;
      }
      &:hover {
        .btn-login {
          display: block;
          margin-top: -5px;
          margin-bottom: -5px;
        }
      }
    }
  }
}
.image-border {
  border: solid 2px white;
}
.button {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  padding: 2px;
  background-clip: content-box;
  height: 80px;
  width: 10%;
  border-radius: 10%;
  margin: 10px 10px 10px 10px;
  position: static;
}

.blue {
  background-color: #7998b9;
  border: solid 2px #7998b9;
}
.red {
  background-color: #c2797c;
  border: solid 2px #c2797c;
}

.yellow {
  background-color: #fedf8c;
  border: solid 2px #fedf8c;
}
.gray {
  background-color: #d3d4d8;
  border: solid 2px #d3d4d8;
}

.upload-wrapper {
  border: none;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
}
.upload-wrapper .avatar-upload .rounded {
  margin: auto;
  width: 400px;
  height: 400px;
}
.upload-wrapper .text-center .btn {
  margin: 0 0.5rem;
}
.upload-wrapper .avatar-edit-image {
  max-width: 100%;
}
.upload-wrapper img {
  max-width: 100%;
}
.upload-wrapper .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}
.upload-wrapper .drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}
.nav nav-tabs flex-column card-header h-100 border-bottom-0 rounded-0 {
  padding-right: 10rem !important;
}
</style>

<style lang="scss">
@import "../../../assets/sass/paper/variables.scss";
@import "../../../assets/sass/matador/_change-theme.scss";
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
@media (max-width: 516px) {
  .subdomain-text {
    text-align: left !important;
  }
}
@media (min-width: 517px) {
  .subdomain-text {
    text-align: right !important;
  }
}
.subdomain {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
</style>
