<template lang="pug">
transition(name="modal")
  div
    .modal-mask
      .modal-wrapper
        .modal-dialog(style="width: 520px;max-width: 95%;" role="document")
          .modal-content
            .modal-body.p-0
              loader(:loading="saving")
                .d-flex.justify-content-between.border-bottom.py-3.px-4.mt-1
                  h4.h5.m-0.text-dark.font-weight-bold Add Organization
                  a.bg-white.border-none(
                    href="javascript:;"
                    role="button",
                    data-dismiss="modal",
                    aria-label="Close"
                    @click="$emit('close')"
                  )
                    div
                      img(:height="14" :width="14" src="@/assets/img/close.png")
                .py-4.my-2.px-4
                  b-form-input(v-model="name" placeholder="Insert Organization")
                .py-3.px-4.d-flex.justify-content-end.align-items-center
                  button.btn.btn-link.mr-3(type="button" @click="$emit('close')") Cancel
                  matador-button.px-4.btn-sm( @click="onSave" :disabled="!name") Add
</template>

<script>
export default {
  data(){
    return {
      saving: false,
      name: '',
      wiper_status: null,
    }
  },
  watch: {
    
  },
  components: {
    
  },
  props: ['close', 'success'],  
  computed: {
    
  },
  methods: {
    onSave(){
      this.$emit('success', this.name);
      this.$emit('close');
    }
  },
  mounted(){
    
  }
}
</script>